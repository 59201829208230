.Mision-vision_pm {
    margin: 0 auto;
    background: #f9f9f9;
}

.container-vision_mision_pm {
    max-width: 1440px;
    display: flex;
    padding-top: 50px;
    margin: 0 auto;
    padding-bottom: 80px;
}

.container-vision, .container-mision , .container-promise-value , .container-propose{
    position: relative;
    height: 257.08px;
    margin-left: 70px;
}

.info-vision, .info-mision, .info-promise_value, .info-propose {
    position: relative;
    z-index: 1024;
    width: 295px;
    height: 254px;
    background: #FFFFFF;
    box-shadow: 0px 3.62686px 18.1343px rgba(0, 0, 0, 0.07);
    border-radius: 30px;
    display: flex;
    justify-content: center;
}

.vision h6, .mision h6, .promise-value h6 , .propose h6 {
    color: #005286;
    font-size: 16px;
    font-family: 'Amble';
    position: relative;
    text-transform: uppercase;
    text-align: center;
    margin-top: 20px;
}

.vision h6::after, .mision h6::after, .promise-value h6::after , .propose h6::after {
    content: '';
    position: absolute;
    top: 30px;
    left: 20%;
    border: 1.72014px solid #005286;
    background: #005286;
    width: 124px;
    height: 1px;
}

.info-mision p , .info-vision p, .info-promise_value p, .info-propose p {
    font-family: 'Amble-light';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 17px;
    width: 216px;
    height: 51px;
    color: #4D4D4C;
    margin-top: 60px;
    text-align: center;
}

.info-promise_value p {
    width: 272px;
    height: 68px;
}

.info-mision strong {
    font-family: 'Amble-bold';
}

.square-mision, .square-vision , .square-promise_value, .square-propose {
    position: absolute;
    z-index: 1;
    width: 295px;
    height: 248.26px;
    background: #D1EBFC;
    border-radius: 30px;
    transform: rotate(-3.38deg);
}

.square-mision ,.square-vision , .square-promise_value , .square-propose {
    left: -20px;
    top: 5px;
}

@media only screen and (max-width: 1440px) {
    .info-about {
        grid-column: 1/4;
        justify-content: center;
    }
    .info-carbajal {
        grid-column: 1/4 !important;
        justify-content: center;
    }
    .mision, .propose, .vision {
        margin-bottom: 30px !important;
    }
    .info-vision, .info-mision, .info-promise_value, .info-propose {
        width: 257px;
    }
    .container-vision, .container-mision , .container-promise-value , .container-propose{
        margin-left: 80px;
    }
    .square-mision, .square-vision , .square-promise_value, .square-propose {
        width: 257px;
    }
}
