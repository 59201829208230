.Container-sb_pm {
    margin: 0 auto;
    background: #FFF;
}

.header-title {
    margin: 0 auto;
    text-align: center;
}

.Container-sb_pm h2 {
    font-family: 'Amble-bold';
    font-size: 50px;
    color: #005286;
    width: 403px;
    margin: 0 auto;
}

.Container-sb_pm .info-sb p {
    font-family: 'Amble-light';
    font-weight: 300;
    font-size: 14px;
    color: #4D4D4C;
    margin-top: 20px;
    text-align: left;
    width: 288px;
}

.Container-sb_pm .header-title p {
    font-family: 'Amble-light';
    font-weight: 300;
    font-size: 14px;
    color: #4D4D4C;
    text-align: center !important;
    text-align: left;
    margin: 20px auto;
    width: 704px;
}

.models-sb {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6,1fr);
    padding: 50px;
}

.model {
    width: 100%;
    width: 288px;
}

.model_1 {
    grid-column: 1/7;
}

.info-sb {
    margin: 0 auto;
}

.header-img {
    text-align: center;
    width: 240px;
}

.header-img p {
    color: #009FE3;
    font-family: 'Amble-bold';
    font-size: 16px;
    margin-top: 30px;
}

.header-img p.w-sb {
    width: 288px !important;
}

.title-container {
    color: #009FE3;
    font-family: 'Amble-bold';
    font-size: 50px;
    width: 521px;
    margin-top: 50px;
    grid-column: 2/4;
}

.objetives {
    display: flex;
    max-width: 1340px;
    align-items: center;
    flex-wrap: wrap;
    margin: 60px auto;
    justify-content: space-between;
}

.objectives-sosten {
    text-align: center;
}

.objectives-sosten p {
    margin-top: 25px;
    color: #4D4D4C;
    font-family: 'Amble-light';
    font-size: 12px;
    width: 174px;
}

.objectives-sosten span {
    color: #1FAAE5;
    font-family: 'Amble-bold';
}

.info-informs {
    grid-column: 2/4;
}

.box-informs-pdf {
    display: flex;
    align-items: center;
    background: #fff;
    width: 70%;
    padding: 8px;
    margin-bottom: 20px;
    box-shadow: 0px 4px 20px rgba(216, 216, 216, 0.3);
    border-radius: 31.5px;
}

.box-informs-pdf p {
    text-decoration: underline;
    margin-left: 15px;
    font-family: 'Amble';
    color: #4D4D4C;
    font-size: 14px;
}

.info-informs h2 {
    font-size: 50px;
    font-family: 'Amble-bold';
    color: #005286;
}

.info-informs p {
    color: #4D4D4C;
    margin-top: 20px;
    width: 383px;
    font-size: 14px;
    font-family: 'Amble-light';
}

.informs-pdfs {
    grid-column: 5/8;
}

.post-sl-sbl {
    grid-column: 1/4;
    position: relative;
    display: flex;
    justify-content: center;
}

.post-img-card-rigth {
    grid-column: 5/7;
    position: relative;
    display: flex;
    justify-content: center;
}

.info-container {
    grid-column: 2/4;
    position: relative;
}

.info-container span {
    font-family: 'Amble-bold';
    color: #4D4D4C;
}

.info-container .title-green-container h6 {
    color: #8DB72E;
    font-family: 'Amble-bold';
    font-size: 30px;
}

.title-white-container h6 {
    color: #fff;
    font-family: 'Amble-bold';
    font-size: 50px;
}

.info-container .title-green-dark-container h6 {
    color: #32711E;
    font-size: 50px;
    width: 521px;
    font-family: 'Amble-bold';
}

.info-container p {
    color: #4D4D4C;
    font-family: 'Amble-light';
    font-size: 14px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.img-container {
    grid-column: 5/7;
}

.info-sl-sbl {
    grid-column: 5/8;
    padding: 0px 50px 50px 50px;
    position: relative;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    width: 400px;
}

.info-pet-sbl {
    grid-column: 5/8;
    position: relative;
    text-align: right;
    display: flex;
    justify-content: center;
    color: #4D4D4C;
    font-family: 'Amble-light';
    right: 200px;
}

.info-pet-sbl div.bold {
    font-family: 'Amble-bold';
}

.info-sbl {
    position: absolute;
    right: -100px;
}

.info-sl-sbl img {
    display: unset !important;
    margin-bottom: 20px;
}

.info-sbl h4 {
    color: #32711E;
    font-size: 50px;
    width: 620px;
    margin-bottom: 20px;
    position: relative;
    font-family: 'Amble-bold';
}

.info-sl-sbl h6 {
    font-family: 'Amble-bold';
    color: #4D4D4C;
    font-size: 14px;
}

.info-sl-sbl p {
    font-family: 'Amble-light';
    color: #4D4D4C;
    font-size: 14px;
    margin-top: 40px;
}

.stb-slick .slick-dots {
    bottom: 10px !important;
    background: transparent !important;
    z-index: 2500 !important;
    padding: 0 !important;
    right: 0px !important;
}

.stb-slick .slick-arrow {
    display: none !important;
}

.stb-slick .slick-dots li {
    border: none !important;
}

.stb-slick .slick-dots ul li button::before {
    display: none;
}

.stb-slick .slick-dots ul li button {
    font-size: 16px !important;
    color: #8DB72E;
    font-family: 'Amble';
}

.stb-slick .slick-dots ul {
    display: flex;
    justify-content: flex-end;
    position: relative;
    right: 300px;
}

.stb-slick .slick-dots ul li.slick-active, .stb-slick .slick-dots ul li {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
}

.stb-slick .slick-dots ul li.slick-active {
    background: #8DB72E;
    border: 1px solid #8DB72E !important;
}

.stb-slick .slick-dots ul li.slick-active button {
    color: #fff;
}

.stb-slick .slick-dots ul li {
    background: transparent;
    border: 1px solid #8DB72E !important;
}

@media only screen and (max-width: 1440px) {
    .title-container {
        grid-column: 1/4;
        margin-left: 100px;
    }
    .container-grid.work {
        padding-top: 180px !important;
    }
    .post-sl-sbl {
        grid-column: 1/4;
    }
    .info-sl-sbl {
        grid-column: 5/8;
    }
    .stb-slick .slick-dots {
        right: 7px !important;
    }
    .objetives {
        max-width: 1240px;
    }
    .info-informs {
        grid-column: 1/4;
        margin-left: 100px;
    }
    .stb-slick .slick-dots ul {
        right: 90px;
    }
    .info-pet-sbl {
        grid-column: 4/8;
        right: 80px;
    }
    .post-pet-sbl {
        grid-column: 1/4;
        right: 200px;
    }
    .info-pm_work.sbl {
        margin-left: 200px !important;
    }
    .post-img-card-rigth {
        left: 140px;
    }
    .square-img-card {
        right: -60px;
    }
    .Slicks-pm.Us .slick-initialized .slick-slide {
        height: 80vh;
    }
}

@media only screen and (max-width:1280px) {
    .new-slick.work.tablet .slick-dots {
        left: -380px !important;
    }
    .elipse-work {
        bottom: -450px;
    }
    .info-client-footer {
        margin-left: 180px !important;
    }
    .content-slick-new {
        height: 80vh !important;
    }
}

/* Accordiones */

.container-accordion {
    max-width: 510px;
    height: 376px;
  }
  details p {
      color: #4c4c4c !important;
      font-family: 'Amble-light';
      max-width: 440px;
      margin: 0 auto;
  }
  details {
    background: #fff;
    border-radius: 31.5px;
    color: #4c4c4c;
    font-family: 'Amble-bold';
    font-size: 14px;
    cursor: pointer;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
    min-height: 48px;
    max-height: 60px;
    -webkit-transform-origin: top center;
            transform-origin: top center;
    -webkit-transform: rotate(0.1deg);
            transform: rotate(0.1deg);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    margin-bottom: 20px;
  }
  details:active {
    color: rgba(255, 255, 255, 0.9);
    border-color: #6d7380;
  }
  details ::-webkit-details-marker {
    display: none;
  }
  summary span {
      margin-left: 10px;
  }
  details + details {
    margin-top: 10px;
  }
  details p {
    color: #fefefe;
    line-height: 1.7;
    margin: 10px 0 0;
    padding: 0 20px 15px;
  }
  details.primary {
    color: #4c4c4c;
    background: #fff;
  }
  details.primary:active {
    color: rgba(255, 255, 255, 0.9);
    border-color: #0d84ff;
  }
  details.success {
    border-color: #67c23a;
    color: rgba(255, 255, 255, 0.9);
    background: #67c23a;
  }
  details.success:hover {
    border-color: #67c23a;
    color: rgba(255, 255, 255, 0.6);
  }
  details.success:active {
    color: rgba(255, 255, 255, 0.9);
    border-color: #529b2e;
  }
  details[open] {
    -webkit-transition: all 0.6s;
    transition: all 0.6s;
    min-height: 100px;
    max-height: 200px;
  }
  summary {
    outline: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 13px;
  }
  summary:selection {
    background: transparent;
  }
  summary .close {
    display: none;
  }
  [open] summary .close {
    display: inline;
  }
  summary .open {
    display: inline;
  }