.container-detail {
    background: #fff;
    margin: 0 auto;
    padding: 40px;
}

.guide-detail_product {
    display: flex;
    align-items: center;
}

.guide-detail_product p {
    font-size: 10px;
    color: #4c4c4c;
    font-family: 'Amble-bold';
}

.guide-detail_product button {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
}

.guide-detail_product span {
    font-size: 10px;
    margin-left: 10px;
    text-transform: uppercase;
    color: #009FE3;
    font-family: 'Amble';
}

.container-detail_product {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    align-items: center;
}

.img-detail_product {
    position: relative;
    display: flex;
    grid-column: 1/3;
    justify-content: center;
    align-items: center;
    width: 435.96px;
    height: 372.32px;
}

.detail-info {
    grid-column: 3/5;
}

.img-detail_product .img-product {
    position: absolute;
    z-index: 2;
}

.title-detail_product h4 {
    color: #005286;
    max-width: 439px;
    font-family: 'Amble-bold';
    font-size: 30px;
}

.list-detailt_product {
    background: #FFFFFF;
    margin-top: 30px;
    max-width: 544px;
    box-shadow: 0px 4px 20px rgba(216, 216, 216, 0.3);
    border-radius: 25px;
}

.list-detailt_product ul {
    list-style: none;
}

.list-detailt_product ul li {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-family: 'Amble-light';
    font-size: 14px;
    color: #4d4d4d;
}

.container-detail_product .square-products {
    position: absolute;
    top: 30px;
    min-width: 435.96px;
    min-height: 372.32px;
    background: #D1EBFC;
    z-index: 1;
    right: -80px;
    border-radius: 58px;
    transform: matrix(0.98, -0.22, -0.22, -0.98, 0, 0);
}
.container-detail_product .img-product {
    width: 407.34px;
    left: 90px;
    bottom: 0px;
}

.container-detail_product .img-product img {
    width: 100%;
}


.list-detailt_product ul li {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-family: 'Amble-light';
    font-size: 14px;
    color: #4d4d4d;
}

.list-detailt_product ul li.gray {
    background: #F9F8F8;
}


.list-detailt_product ul li p{
    font-family: 'Amble';
    font-size: 14px;
    color: #000;
}

.whant-need {
    display: block;
    margin-top: 30px;
}

.whant-need label {
    width: 100%;
    font-family: 'Amble';
    font-size: 14px;
    color: #4d4d4d;
}

.whant-need input {
    margin-top: 15px;
    display: block;
    border: 1px solid #C4C4C4;
    border-radius: 40px;
    padding: 10px 60px 10px 20px;
    font-family: 'Amble-light';
    font-size: 14px;
    outline: none;
    color: #4d4d4d;
}

.whant-need input::placeholder {
    color: #949090;
}

.btn-cotize button {
    background: #8DB72E;
    border-radius: 30px;
    font-family: 'Amble';
    color: #fff;
    font-size: 14px;
    margin-top: 30px;
    border: none;
    padding: 10px 30px;
    outline: none;
    cursor: pointer;
}