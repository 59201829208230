@font-face {
    font-family: 'Amble';
    src: url('../fonts/Amble-Regular.otf');
}

@font-face {
    font-family: 'Amble-Light';
    src: url('../fonts/Amble-Light.otf');
}

@font-face {
    font-family: 'Amble-Bold';
    src: url('../fonts/Amble-Bold.otf');
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.slick-slide div {
    color: #4d4d4d;
    font-family: 'Amble-Light';
}

.Home .slick-slide {
    overflow: hidden;
}

.autocomplete {
    position: absolute;
    list-style: none;
    text-align: left;
    background: #fff;
    width: 100%;
    top: 60px;
    z-index: 9999999;
    border-radius: 20px;
    padding: 20px;
}

.autocomplete li {
    padding: 10px;
    font-family: 'Amble-light';
    font-size: 13px;
    cursor: pointer
}

.autocomplete li:hover {
    background: lightblue;
    border-radius: 10px;
}

.related-products_add {
    display: flex;
    justify-content: space-between;
}

body {
    background: #F9F9F9;
    position: relative;
}

.container-header {
    position: fixed;
    z-index: 9999999;
    width: 100%;
}

.container {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    background: #FFFFFF;
    padding-bottom: 80px;
}

.container-grid {
    display: grid;
    grid-template-columns: repeat(7,1fr);
}

.filter-button {
    display: none;
}

.MuiFormControl-root {
    width: 100%;
}

.MuiInput-underline:before , .MuiInput-underline:after{
    border: none !important;
}

.MuiFormControl-marginNormal {
    margin: 0 !important;
}

.MuiInputAdornment-root {
    position: absolute;
    right: 0;
}

.MuiInputBase-input {
    height: auto !important;
}

.css-26l3qy-menu {
    z-index: 999999 !important;
}

.Map{
    display: block;
}

.Name-category.movil {
    display: none;
}

.Products-Quote .movil svg {
    display: none;
}

button.gm-control-active, .gm-svpc, .gm-style-mtc, a div img {
    display: none;
}

.title h6 {
    font-family: 'Raleway';
    font-size: 13px;
}

.gmnoprint div {
    background: transparent !important;
}

.info-container > div > div > div > div > div  {
    border-radius: 50px
}

.content-login {
    flex-direction: column;  
}

.copy-socials.movil {
    display: none;
}

.no-quote {
    padding: 20px 20px;
    padding-bottom: 80px ;
}

@media only screen and (max-width: 1280px) {
    .Slicks-pm ul.slick-dots {
        right: 50px !important;
    }
    .menu-categories ul {
        display: inline-flex !important;
        overflow-x: scroll !important;
        justify-content: start !important;
    }
    li a.category {
        width: 170px !important;
        margin-left: 40px;
    }
    li a.active-category {
        width: 170px !important;
        margin-left: 40px;
    }
    .form-grid {
        grid-template-columns: repeat(1,1fr) !important;
    }
}

@media only screen and (max-width: 1280px) {
    .Form-container_postulation {
        padding: 40px !important;
    }
}

@media screen and (max-height: 1064px) and (max-width: 1050px) {
    .Rotate-slick.Us.Main .info-slick.Us.ls {
        top: 740px !important;
        left: -125px !important;
    }
    .Rotate-slick.Us.Main.tablet .info-slick {
        top: 890px !important;
        left: -100px !important;
    }
    .Rotate-slick.Us.Sb.ls::after {
        top: 355px !important;
        left: -240px !important;
    }
    .Rotate-slick.Us.Wu::after {
        top: 515px !important;
        left: -280px !important;
    }
    .Rotate-slick.Us.Cl.ls::after {
        top: 595px !important;
        left: -260px !important;
    }
    .Rotate-slick.Us.New.ls::after {
        top: 435px !important;
        left: -380px !important;
    }
    .Rotate-slick.Us.Ctt::after {
        top: 515px !important;
        left: -380px !important;
    }
    .form-container .grid-form_doble , .form-container .grid-form_triple , .grid-form_doble_right{
        grid-template-columns: repeat(1,1fr) !important;
    }
    .form-container .uploader , .form-container .btn-adj {
        grid-column: 1/5 !important;
    }
    .Form-container_postulation h4 {
        font-size: 22px !important;
        width: 100% !important;
    }
    .form-container .btn-send button {
        top: 42px !important;
        position: relative;
    }
    .Rotate-slick::after {
        top: 354px !important;
    }
    .Elipse {
        top: 30px !important;
        left: 80px !important;
        transform: rotate(19.34deg) !important;
        background-repeat: no-repeat;
    }
    .Elipse img {
        width: 210px !important;
        height: 240px !important;
    }
    .biof {
        top: 150px !important;
        left: 140px !important;
    }
    .biof img {
        width: 100px !important;
        height: 70px !important;
    }
    .Footer-pm {
        display: none;
    }
    .container-grid.work .new-slick.work.tablet .card-work {
        height: 400px !important;
    }
    .container-grid.first .info-container p {
        font-size: 12px !important;
    }
    .container-grid-ds-forms ,.img-input-ds.edit  {
        grid-template-columns: repeat(1,1fr) !important;
        grid-gap: 0px !important;
    }
    .grid-container-ds {
        grid-template-columns: repeat(1,1fr) !important;
    }
    .Footer-pm.movil {
        display: block !important;
    }
    .home .container-known .info-known {
        grid-column: 1/8 !important;
        justify-content: flex-start;
        margin-left: 35px !important;
    }
    .img-new_slick_us img ,.img-new_slick_us::after{
        border-radius: 30px !important;
    }
    .Container-sb_pm .sb_pm .header-title p {
        margin-top: 40px !important;
    }
    .Home .content-slick-home {
        height: 95vh !important;
    }
    .makeStyles-paper-1 {
        width: 90% !important;
    }
    .Products-Quote.Search-Products .filter-button , .Products-Quote.Search-Products .pagination {
        display: none;
    }
    .container-grid.clients-page .info-clients  {
        margin-left: 35px !important;
        grid-column: 1/8 !important;
    }
    .container-clients .title-client {
        grid-column: 1/8 !important;
        margin-left: 35px !important;
    }
    .container-grid.clients-page .info-clients h2 {
        font-size: 22px !important;
    }
    .container-grid.clients-page .info-clients p {
        max-width: 400px !important;
    }
    .container .container-grid.clients-page .info-client-footer {
        margin-left: 35px !important;
        max-width: 90% !important;
    }
    .container .container-grid.clients-page .info-client-footer p ,.info-client-footer p {
        max-width: 100% !important;
    }
    .Known-pm.home .products-known {
        grid-column: 1/8 !important;
        justify-content: flex-start;
    }
    .home .container-known .info-known h3 {
        width: 232px !important;
        line-height: 25px !important;
    }
    .home .container-known .info-known p ,.bioform-pm p, .line-products_pm p, .material-pm p, .use-app_pm p {
        font-size: 14px !important;
    }
    .square-sl-sbl,.square-distributor ,.container-known_video .play-known .square ,.tablet .container-news .square-slick , .wc .square-carbajal 
    ,.card-about .square.us ,.container-grid .play-known .square{
        max-width: 380px;
        max-height: 280px;
        border-radius: 30px !important;
        left: -20px !important;
    }
    .form-wizard .form-wizard.movil .form-wizard-steps li {
        width: 100% !important;
    }
    .form-wizard {
        margin-top: 40px !important;
    }
    .tablet .container-news .square-slick {
        max-width: 300px !important;
        top: 60px !important;
        border-radius: 30px !important;
    }
    .img-video_known .img img ,.img-video_known .img::after ,.img-new_slick img ,.img-distributor::after,.img-distributor_hm img,.img-new_slick::after {
        border-radius: 20px !important;
    }
    .footer-client_main h6 {
        font-size: 12px !important;
    }
    .types-clients svg {
        width: 35px !important;
    }
    .container-grid.clients-page div#industrial, .container-grid.clients-page div#foodservices {
        padding-top: 0px !important;
    }
    .post-sl-sbl .square-sl-sbl {
        left: 20px !important;
        top: 60px;
    }
    .info-clients p, .info-client-footer p {
        font-size: 12px !important;
    }
    .container .container-grid.why .play-button_pm {
        top: 250px !important;
        right: 40px !important;
    }
    .container-grid.first .square-img-card {
        max-width: 380px !important;
        max-height: 280px;
        left: 20px !important;
    }
    .container-grid .play-known .square {
        left: 20px !important;
    }
    .container-grid.pet .info-pet-sbl {
        margin-left: 35px !important;
    }
    .wc .square-carbajal  {
        transform: rotate(-9.08deg) !important;
        top: 30px;
    }
    .About-us_pm .card-about .square.us {
        top: -38px;
        width: 270px !important;
        border-radius: 30px !important;
        left: -20px !important;
    }
    .flex-pet-sbl {
        flex-wrap: wrap;
        text-align: left !important;
    }
    .info-pet-sbl {
        right: 0px !important;
    }
    .flex-pet-sbl > div {
        padding: 0px;
        margin-left: 0px !important;
        font-size: 12px !important;
        text-align: center !important;
    }
    .flex-pet-sbl > div svg {
        width: 100px !important;
    }
    .card-body p {
        font-size: 9px !important;
    }
    .container .container-grid.work .info-new_slick {
        margin-left: 35px !important;
    }
    .container-grid.why .play-known .square {
        left: 20px !important;
    }
    .container-grid.tonel .info-container , .container-grid.first .info-container ,
    .container-grid.community .info-container,.container-grid.information .certifications-companies {
        grid-column: 1/8 !important;
        margin-left: 35px !important;
    }
    .container-grid.information .certifications-companies .info-container {
        right: 0 !important;
    }
    .container-grid.information .certifications-companies .info-container p ,.info-container .title-green-dark-container h6 {
        font-size: 14px !important;
        width: 90% !important;
    }
    .container-grid.work .card-work {
        max-width: 400px !important;
    }
    .container-grid.work .slick-slide img {
        width: 100%;
    }
    .container-grid.elipse h6 {
        margin-top: 30px !important;
        font-size: 22px !important ;
        max-width: 300px;
        margin: 0 auto;
    }
    .container-grid.elipse p {
        max-width: 400px;
        margin: 0 auto;
        font-size: 10px !important;
    }
    .container-grid.elipse .play-known.elipse .square {
        left: 25px !important;
    }
    .container-grid.information .play-known.info .square {
        left: 20px !important;
    }
    .container-grid.information .certifications-companies {
        justify-content: flex-start !important;
    }
    .container-grid.elipse .info-container.question ,.container-grid.elipse .box-container.question {
        grid-column: 1/8 !important;
        margin-left: 35px !important;
        justify-content: flex-start !important;
    }
    .container-grid.elipse .box-container.question {
        margin-top: 50px !important;
        max-width: 380px !important;
    }
    .card-work {
        margin-left: 0px !important;
        max-width: 400px !important;
    }
    .post-pet-sbl.activity .img-new_slick.activity {
        max-width: 300px !important ;
        max-height: 400px !important;
        left: 0px !important;
    }
    .post-pet-sbl.activity .square-pet-sbl.activity {
        max-width: 300px !important ;
        max-height: 400px !important;
    }
    .container-grid.videos .slick-list .square-pet-sbl.activity {
        top: 50px !important;
        left: 40px !important;
    }
    .flex-pet-sbl > div > div {
        margin-bottom: 30px !important;
    }
    .container-grid.clients-page {
        display: block !important;
    }
    .container-grid.videos  {
        padding-top: 0px !important;
    }
    .container-grid.videos .new-slick.work.activity .info-post-activity {
        grid-row: 1/2 !important;
    }
    .container-grid.videos .new-slick.work.activity .slick-slide > div > div { 
        display: block !important;
        margin-left: 0px !important;
    }
    .container-grid.videos .play-button_pm.activity {
        left: 250px !important;
        top: 300px !important;
    }
    .img-about_us .img img ,.img-about_us .img::after{
        border-radius: 30px !important;
    }
    .About-us_pm .img-about_us .img::after {
        max-width: 380px;
        height: 100% !important;
    }
    .info-carbajal.about p {
        font-size: 12px !important;
    }
    .info-about h3 {
        font-size: 16px !important;
        font-family: 'Amble-Bold';
    }
    .Certifications .info-certifications , .Certifications .certifications-companies ,.container-news .info-new_slick {
        grid-column: 1/8 !important;
        margin-left: 35px !important;
    }
    .Certifications .certifications-companies .companies_us {
        margin-bottom: 0px !important
    }
    .container-news .info-new_slick h6, .container-news .info-new_slick p {
        margin-top: 8px !important;
        margin-bottom: 0px !important;
        max-width: 400px !important;
    }
    .container-news .info-new_slick h6 {
        margin-bottom: 20px !important;
    }
    .img-values {
        padding: 25px !important;
    }
    .img-values div{
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }
    h6.title-values {
        font-size: 22px !important;
        font-family: 'Amble-Bold';
        margin-left: 35px;
        justify-content: flex-start;
    }
    .Mision-vision_pm .info-vision, .Mision-vision_pm .info-mision, .Mision-vision_pm .info-promise_value,
    .Mision-vision_pm .info-propose, .Mision-vision_pm .square-mision, .Mision-vision_pm .square-vision, .Mision-vision_pm .square-promise_value,.Mision-vision_pm .square-propose {
        width: 340px !important;
    }
    .card-about .img-about_us {
        left: 0px !important;
    }
    .container-informations {
        padding-bottom: 50px !important;
    }
    .container-known_video .play-known .square {
        left: -35px !important;
    }
    .play-button_pm {
        top: 140px !important;
        right: -50px !important;
    }
    .bioform-pm::after, .line-products_pm::after, .material-pm::after, .use-app_pm::after {
        height: 100% !important;
    }
    .bioform-pm a, .line-products_pm a, .material-pm a, .use-app_pm a {
        display: flex;
        justify-content: center;
    }
    .img-card, .square-img-card.right-up,.News-pm_us .img-new_slick_us,.News-pm_us .img-new_slick,.img-distributor  ,.img-video_known .img ,.tablet .img-new_slick ,.img-carbajal.about ,.card-about .img-about_us {
        max-width: 380px !important;
        max-height: 280px !important;
    }
    .info-sbl {
        max-width: 90% !important;
    }
    .square-img-card {
        border-radius:  30px !important;
    }
    .News-pm_us .new-slick.plant.tablet .img-new_slick ,.container-news .img-new_slick_us {
        left: -10px !important;
    }
    .new-slick.plant.tablet .square-slick {
        top: 60px !important;
        left: -30px !important;
    }
    .container-news .post-new_slick_us {
        margin-top: 0px !important;
    }
    .img-card img ,.etic .square-img-card.right-up {
        border-radius: 30px !important;
    }
    .page-new {
        overflow: hidden;
    }
    .Map_pm .img-map_pm {
        grid-column: 1/11 !important;
    }
    .Map_pm .img-map_pm img {
        max-width: 70% !important;
    }
    .title-white-container h6 {
        font-size: 22px !important;
    }
    .etic-pro .info-container {
        grid-column: 1/8 !important;
        margin-left: 35px !important;
    }
    .container-grid.sbl .info-pm {
        margin-left: 35px !important;
    }
    .etic-pro .info-container p {
        font-size: 12px !important;
    }
    .container-grid .etic .square-img-card.right-up {
        right: 17% !important;
    }
    .Footer-news_us .info-footer_news_us p {
        max-width: 90% !important;
    }
    .Footer-news_us .info-footer_news_us ,.container-grid.why .info-pm{ 
        grid-column: 1/8 !important;
        margin-left: 35px;
    }
    .About-us_pm .info-about p ,.container-grid.why .info-pm p {
        max-width: 85% !important;
    }
    .container-grid.why .square {
        left: 20px !important;
        border-radius: 30px !important;
    }
    .img-carbajal.about {
        margin-left: 50px !important;
    }
    .info-footer_news_us p {
        font-size: 12px !important;
    }
    .img-distributor img, .img-video_known .img img {
        max-height: 100% !important;
    }
    .Known-pm.home .container-products_pm img  {
        max-width: 184px !important;
        max-height: 144px !important;
        border-radius: 20px !important;
    }
    .line-products_pm::after {
        height: 100% !important;
        top: 2px !important;
    }
    .info-known_our h4 ,.info-post-activity p{
        font-size: 22px !important;
        width: 90% !important;
        margin: 0 auto;
    }
    .info-pm h6 {
        font-size: 22px !important;
        width: 90% !important;
    }
    .client-info {
        grid-column: 1/8 !important;
    }
    .info-client-footer {
        position: relative !important;
    }
    .filter-shadow {
        border-radius: 20px !important;
    }
    .material-pm::after {
        height: 97% !important;
        top: 2px !important;
    }
    .home .products-known {
        margin-top: 30px;
    }
    .home .container-products_pm {
        margin-left: 35px !important;
        grid-gap: 30px !important;
    }
    .home .container-title-new h6 {
        grid-column: 1/7 !important;
    }
    .home .container-known {
        padding-bottom: 40px !important;
    }
    .container-products_business svg {
        width: 79px !important;
        height: 79px !important;
        margin: 0 !important;
    }
    .container-products_business p {
        font-size: 13px !important;
    }
    .Known-pm.home .container-products_business div {
        margin-left: 10px !important;
        text-align: center;
    }
    .Known-pm div .know-lines {
        margin-left: 20px !important;
    }
    .Known-pm.home .container-products_business  {
        width: 100% !important;
        margin-left: 0 !important;
        justify-content: center !important;
        grid-column: 1/8 !important;
        margin-top: 30px !important;
    }
    .container-informations .info-carbajal.about ,.container-informations .info-map_pm.title, .About-us_pm .info-about{
        margin-left: 35px;
        max-width: 90% !important;
        grid-column: 1/8 !important;
    }
    .Certifications .container-news .img-new_slick {
        left: 0px !important;
    }
    .info-carbajal.about p {
        max-width: 85% !important;
    }
    .container-informations.present {
        padding-bottom: 0px !important;
    }
    .container-grid_img {
        margin-top: 20px !important;
    }
    .container-informations .info-map_pm.title span {
        font-size: 18px !important;
        line-height: 20px !important;
    }
    .container-informations .info-map_pm.title h6 ,.info-container .title-green-dark-container h6 {
        font-size: 22px !important;
    }
    .bioform-pm::after, .line-products_pm::after, .material-pm::after, .use-app_pm::after {
        border-radius: 20px !important;
    }
    .container-title-new h6 {
        grid-column: 1/8 !important;
        font-size: 22px !important;
        width: 204px !important;
    }
    .info-known h3 {
        font-size: 22px !important;
    }
    .options-pm-rs {
        grid-column: 10/16 !important;
        justify-content: flex-end !important;
        margin-right: 15px !important;
    }
    .close-movil {
        position: relative;
        top: -6px;
        left: 2px;
    }
    .container-header-rs .Logo-pm ,.img-carbajal.about{
        grid-column: 1/8 !important;
    }
    .info-slick {
        top: 650px !important;
        left: 0px !important;
        transform: rotate(19deg) !important;
    }
    .info-slick h2 {
        font-size: 30px !important;
        width: 223px !important;
    }
    .info-slick p {
        font-size: 14px !important;
    }
    .Rotate-slick::after, .Rotate-slick::after {
        min-height: 15% !important;
        background-size: unset !important;
        min-width: 90% !important;
    }
    .Rotate-slick::before {
        left: -23px !important;
        height: 144vh !important;
        /* background-color: rgba(0, 82, 134, 0.4) !important; */
        transform: rotate(19deg) !important;
    }
    .Rotate-slick {
        height: 87vh !important;
        left: -80px !important;
        /* top: -380px !important; */
        top: -380px !important;
        transform: rotate(-19deg) !important;
        border-radius: 60px !important;
    }
    .Rotate-slick::after {
        left: -28px !important;
        top: 300px !important;
        background-size: unset !important;
        transform: rotate(19deg) !important;
    }
    .info-sbl h4 {
        max-width: 90% !important;
    }
    .Container-sb_pm h2 {
        max-width: 90% !important;
    }
    .models-sb {
        padding: 30px !important;
    }
    .container-grid.first {
        margin-top: 60px !important;
    }
    .container-grid .info-informs p , .container-grid .info-informs, .informs-pdfs ,.info-informs h2{
        max-width: 90% !important;
    }
    .info-container .title-green-container h6 {
        font-size: 22px !important;
    }
    .container-grid.community {
        margin-top: 60px !important;
    }
    summary {
        font-size: 10px !important;
    }
    .text-new {
        font-size: 12px !important;
        margin-bottom: 10px;
    }
    .info-about p {
        font-size: 12px !important;
    }
    summary svg {
        width: 30px !important;
        padding: 6px !important;
    }
    .Radius {
        height: 80vh !important;
        left: -110px !important;
        transform: rotate(-19deg) !important;
        border-radius: 60px !important;
    }
    .tree-pm.banner img {
        top: 140px !important;
        left: -50px !important;
        width: 150px !important;
    }
    .content-slick-home {
        height: 85vh !important;
    }
    .container-distributor .info-distributor_hm, .container-distributor .img-distributor_hm {
        grid-column: 1/8 !important;
    }
    .container-distributor .info-distributor_hm h3 {
        font-size: 22px !important;
    }
    .companies-second_us .img-company-gplast,.companies_us .img-company-sgiso,.companies_us .img-company-sgs ,.companies-second_us .img-company-gp ,.companies-second_us .img-company-gpl ,.companies-second_us .img-company-gpw ,.companies_us .img-company-abe, .companies-second_us .img-company-abe, .companies-second_us .img-company-gptw21 {
        width: 57px !important;
        height: 56px !important;
        margin-right: 20px !important;
    }
    .companies-second_us .img-company-gptw21 {
        width: 47px !important;
        height: 80px !important;
        margin-right: 20px !important;
        right: 0px !important;
    }
    .companies_us .img-company-brc {
        width: 78px !important;
        height: 77px !important;
        top: 10px !important;
    }
    .container-clients {
        padding-top: 50px !important;
    }
    .Clients-pm .slick-track .img-clients .img-client_slick ,.client-slick .slick-track .img-clients .img-client_slick {
        width: 98px !important;
        height: 98px !important;
    }
    .Home {
        overflow:  hidden !important;
    }
    .Clients-pm {
        overflow-y:  hidden !important;
    }
    .client-slick .slick-slide .img-client_slick img {
        width: 45px !important;
    }
    .container-news .img-new_slick_us::after {
        height: 100% !important;
    }
    .container-news .img-new_slick_us img {
        height: 100% !important;
    }
    /* Header */
    .header-rs {
        position: fixed !important;
    }
    /* Titulos */
    .container-detail_new h2,.info-certifications h6 ,.info-new_slick h4,.title-client h4 ,.info-footer_news_us h6,.Container-sb_pm h2 {
        font-size: 22px !important;
    }
    .container-detail_new h2 {
        max-width: 85% !important;
    }
    .info-detail .text {
        padding: 20px 0px !important;
    }
    .Detail-new_pm {
        height: 35vh !important;
    }
    .container-detail_new.news-search .slick-dots {
        width: 40% !important;
    }
    .newpaper-gallery {
        margin-left: 0px !important;
        max-width: 90%;
        margin: 0 auto !important;
    }
    .info-certifications span ,.info-new_slick h6, .certifications-companies h6.companies-title, .certifications-companies h6.companies-title_second,
    .info-footer_news_us div.span {
        font-size: 16px !important;
    }
    .info-footer_news_us div.span {
        max-width: 90% !important;
    }
    .info-certifications p ,.info-new_slick p{
        font-size: 12px !important;
        max-width: 90% !important;
    }
    .title-blue-container h3 {
        width: 90% !important;
    }
    .info-product_bf h4 ,.info-product_bf_2 h4,.info-product_bf_3 h4 ,.info-product_bf_4 h4 {
        font-size: 22px !important;
        max-width: 60% !important;
    }
    .populate-slick .slick-next::before, .populate-slick .slick-prev::before {
        display: none;
    }
    /* Banners */ 
    .Slicks-pm.Us .Radius.Us {
        height: 40vh !important;
        border-radius: 60px !important;
        background: #D1EBFC !important;
        left: -40px !important;
        top: -240px !important;
        transform: rotate(-15deg) !important;
    }
    .Slicks-pm .info-slick.Us {
        top: 850px !important;
    }
    .img-footer_new_us svg, .img-footer_new_us img, .Container-sb_pm .header-title p {
        max-width: 90% !important;
        margin: 0 auto !important;
    }
    .img-footer_new_us {
        display: flex;
    }
    .etic-pro p {
        width: 90% !important;
    }
    .Container-sb_pm {
        padding-top: 20px !important;
    }
    .container-grid .title-container {
        grid-column: 1/8 !important;
        margin-left: 35px !important;
        font-size: 22px !important;
    }
    .container-grid .info-informs ,.informs-pdfs {
        grid-column: 1/8 !important;
        margin-left: 35px !important;
    }
    .informs-pdfs {
        margin-top: 40px !important;
    }
    .info-informs h2 ,.info-sbl h4{
        font-size: 22px !important;
    }
    .info-informs p {
        max-width: 400px !important;
    }
    .container-grid .info-sl-sbl {
        padding: 0px !important;
        margin-left: 30px !important;
        justify-content: flex-start;
    }
    .info-sl-sbl p {
        font-size: 12px !important;
        max-width: 400px !important;
    }
    .box-informs-pdf {
        width: 90% !important;
    }
    .objetives {
        justify-content: center !important;
    }
    .objectives-sosten {
        margin: 30px 10px !important;
    }
    .models-sb {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
    .Container-sb_pm .header-title p {
        text-align: left !important;
    }
    .info-slick p, .info-slick_us p, .info-slick_sb p, .info-slick_wwu p, .info-slick_clts p, .info-slick_news p {
        font-family: 'Amble-Bold' !important;
        width: 267px !important;
    }
    .Rotate-slick.Us {
        border-radius: 60px !important;
        top: -680px !important;
        left: -100px !important;
        transform: rotate(-19deg) !important;
        height: 98vh !important;
    }
    .news-search h2 {
        text-align: left !important;
        margin-left: 35px;
        font-size: 22px !important;
    }
    .gallery-title {
        font-size: 12px !important;
    }
    .news-search .text {
        display: none !important;
    }
    .search-date ,.container-view_journalists {
        max-width: 90% !important;
    }
    .container-detail {
        padding: 0px !important;
    }
    .container-detail_product .detail-info {
        margin-left: 35px !important;
    }
    .Detail-Product_pm.movil .img-detail_product {
        justify-content: flex-start !important;
        right: 160px !important;
    }
    .container-detail_product .detail-info {
        padding-top: 20px !important;
    }
    .Detail-Product_pm.movil .container-detail_product .img-product {
        left: 180px !important;
        top: 40px !important;
        max-width: 340px !important;
        max-height: 340px !important;
    }
    .container-detail_product  {
        padding-bottom: 20px !important;
    }
    .guide-detail_product {
        padding: 30px !important;
    }
    .products-default > div {
        margin-bottom: 0px !important;
    }
    .products-default h5 {
        width: 100% !important;
        left: -20px !important;
    }
    .pagination {
        padding: 10px 0 !important;
    }
    .Detail-Product_pm.movil .square-products {
        min-width: 300px !important;
        min-height: 300px !important;
    }
    .container-view_journalists {
        grid-template-columns: repeat(1,1fr) !important;
    }
    .info-view_journalists ,.img-view_journalists {
        grid-column: 1/2 !important;
        width: 100% !important;
        height: 180px;
    }
    .img-view_journalists img , .img-view_journalists::after{
        border-top-left-radius: 30px !important;
        border-bottom-left-radius: 30px !important;
    }
    .container-gallery {
        grid-template-columns: repeat(2,1fr) !important;
        max-width: 90% !important;
        grid-gap: 20px !important;
    }
    .tree-new.contact-info img , .tree-new.contact img{
        display: none;
    }
    .Distributor-search  {
        padding-bottom: 8px !important;
    }
    .Distributor-search .Search  .Search-container.distributor{
        grid-column: 2/11;
        max-width: 95% !important;
        padding-bottom: 0px !important;
    }
    .container-grid.distributor .info-container ,.container-grid .ubications {
        grid-column: 1/8 !important;
        margin-left: 35px !important;
        max-width: 400px !important;
    }
    .Menu-Category_pm .Search.product button.btn-back {
        display: none !important;
    }
    .Container-Products_Quote_pm {
        grid-template-rows: repeat(4,330px) !important;
        max-width: 90%;
        margin: 0 auto;
    }
    .Products-populate_pm h4 {
        font-size: 22px !important;
    }
    .Products-Quote_1 , .Products-Quote_2 ,.Products-Quote_3 ,.Products-Quote_4{
        grid-column: 1/9 !important;
        border-radius: 20px !important;
    }
    .makeStyles-paper-1 {
        max-width: 90% !important;
    }
    .Sidebar-Material_Quote {
        display: none;
    }
    .Products-Quote {
        grid-column: 1/12 !important;
    }
    .square-products, .products-default .square-products {
        max-width: 120px !important;
        max-height: 120px !important;
        border-radius: 26.8997px !important;
    }
    .products-default {
        margin-left: 35px !important;
    }
    .products-default .product {
        justify-content: flex-start !important;
        max-width: 200px !important;
        max-height: 200px !important;
    }
    .products-default .product .img-product {
        max-width: 116px !important;
        max-height: 116px !important;
    }
    .img-card::after  ,.img-card{
        border-radius: 30px !important;
    }
    .container-filter_pm {
        border-radius:  0px !important;
    }
    .products-default h5 {
        width: 100%;
    }
    .Name-category {
        display: none;
    }
    .Count-products {
        grid-column: 1/7 !important;
    }
    .Count-products::after {
        display: none;
    }
    .product > a {
        top: 90px !important;
        font-size: 9px !important;
        right: 20px !important;
    }
    .Products-Quote {
        padding: 20px 0px !important;
    }
    .Search .Search-container {
        grid-column: 2/11 !important;
    }
    .info-container > div > div > div > div > div {
        border-radius: 0 !important;
    }
    .container-grid .info-container.map-distributor {
        grid-column: 1/8 !important;
    }
    .container-grid.distributor .title-blue-container h3{
        font-size: 22px !important;
    }
    .footer-contact .container-grid {
        grid-gap: 20px !important;
    }
    .form-contact h2 {
        font-size: 22px !important;
    }
    .marker-contact , .call-contact{
        grid-column: 1/8 !important;
        margin-left: 35px !important;
        justify-content: flex-start !important;
    }
    .info-view_journalists h2 {
        margin-left: 0px !important;
    }
    .Rotate-slick.Us::after {
        transform: rotate(19deg) !important;
        left: -450px !important;
        top: 440px !important
    }
    .filter-button {
        display: block;
        max-width: 70%;
        margin: 0 auto;
        margin-top: 50px;
    }
    .filter-button button {
        width: 100%;
        display: flex;
        justify-content: center;
        border: 1px solid #4D4D4C;
        box-sizing: border-box;
        border-radius: 20px;
        padding: 13px;
        background: #fff;
    }
    .products-populate img {
        position: relative;
        top: -30px !important;
        left: 5px !important;
    }
    .products-populate h5 {
        position: relative;
        top: -0px !important
    }
    .makeStyles-paper-2 {
        max-width: 90% !important;
    }
    .makeStyles-paper-2 .Materials-sidebar_pm {
        margin-top: 0px !important;
    }
    div[role="presentation"] {
        overflow: auto;
    }
    .makeStyles-paper-2 .close-modal-filter {
        display: flex;
        justify-content: flex-end;
    }

    .makeStyles-paper-2 .line {
        border-bottom: 1px solid rgba(118, 118, 118, 0.55);
    }
    .Products-Quote .movil {
        display: flex;
    }
    .card-body h6 {
        font-size: 12px !important;
    }
    .Products-Quote .movil svg {
        margin-left: 34px;
        display: block;
    }
    .Products-Quote p.path {
        margin-bottom: 0px !important;
        margin-left: 10px;
    }
    .Name-category.movil {
        display: block;
    }
    .Name-category.movil p {
        color: rgb(0, 159, 227);
        font-size: 16px;
        margin-left: 24px;
        margin-bottom: 10px !important;
    }
    .info-box-quote {
        grid-column: 1/8 !important;
    }
    .info-box-quote .info h3 {
        font-size: 14px !important;
    }
    .info-box-quote .img-quote {
        margin-left: 0px !important;
        max-width: 130px !important;
    }
    .delete-quote {
        grid-column: 8/9 !important;
    }
    .container-dates .container-dates-title ,.container-form-dates{
        background: transparent;
        box-shadow: none;
        border-radius: 0px !important;
    }
    .form-wizard .form-wizard-previous-btn , .container-quotes-products.step-final {
        display: none;
    }
    button.send-cart.complete {
        background: #32711E !important;
    }
    form.form-quote {
        margin-top: 0;
    }
    .form-wizard .form-group {
        text-align: center !important;
    }
    .form-wizard {
        overflow: hidden !important;
    }
    .form-wizard .form-wizard-steps {
        margin-top: 50px !important;
    }
    .delete-quote button span {
        display: none !important;
    }
    .quote-count ,.box-quote {
        border-radius: 0px !important;
    }
    /* Footer */
    .container-footer, .copy-socials {
        padding: 0px !important;
        margin-bottom: 0px !important;
    }
    .copy-socials {
        flex-direction: row-reverse;
    }
    .copy-socials .copy-right div.span {
        display: none;
    }
    .Footer-pm .logo {
        margin-bottom: 30px;
        padding: 20px 20px !important;
        border-bottom: 1px solid #a8a8a8;
    }
    .Footer-pm .declaration , .Footer-pm .information ,.Footer-pm .contact{
        margin-bottom: 23px !important;
        border-bottom: 1px solid #a8a8a8;
        padding: 0px 20px 10px 20px;
    }
    .Footer-pm hr {
        display: none;
    }
    .container-footer .title ,.container-footer h6 {
        width: 100% !important;
    }
    .contact .info-contact div, .declaration .info-contact div, .information .info-contact div {
        margin-top: 10px !important;
    }
    .Footer-pm .logo , .Footer-pm .declaration , .Footer-pm .information , .Footer-pm .contact{
        grid-column: 1/10 !important;
        justify-content: flex-start !important;
    }
    .copy-socials.movil {
        display: block;
        text-align: center;
    }
    .copy-socials.desktop {
        display: none;
    }
    .copy-socials.movil .copy-right {
        justify-content: center !important;
        padding: 0px 0px 10px 0px;
        border-bottom: 1px solid #a8a8a8;
    }
    .copy-socials.movil .copy {
        padding: 20px;
    }
    .title-detail_product h4 {
        font-size: 22px !important;
    }
}


@media screen and (max-height: 896px) and (max-width: 450px) {
    .Rotate-slick.Us.Wu.ls::after {
        top: 450px !important;
        left: -450px !important;
    }
    .Rotate-slick.Us.Ctt.ls::after {
        top: 450px !important;
        left: -550px !important;
    }
    .info-slick {
        top: 640px !important;
        left: 0px !important;
    }
    .Home .content-slick-home {
        height: 87vh !important;
    }
    .container-known_video {
        padding-bottom: 50px;
    }
    .Known-pm.home .container-products_pm img {
        max-width: 144px !important;
        max-height: 104px !important;
    }
    .bioform-pm p, .line-products_pm p, .material-pm p, .use-app_pm p {
        top: 40px !important;
    }
    .container-products_business svg {
        width: 59px !important;
        height: 59px !important;
    }
    .square-img-card.right-up,.img-card, .square-img-card.right-up, .News-pm_us .img-new_slick_us, .News-pm_us .img-new_slick, .img-distributor, .img-video_known .img, .tablet .img-new_slick, .img-carbajal.about, .card-about .img-about_us {
        max-width: 320px !important;
        max-height: 220px !important;
    }
    .container-grid.first .square-img-card,.square-sl-sbl, .square-distributor, .container-known_video .play-known .square, .tablet .container-news .square-slick, .wc .square-carbajal, .card-about .square.us, .container-grid .play-known .square {
        max-width: 320px !important;
        max-height: 220px !important;
    }
    .tablet .container-news .square-slick {
        max-width: 280px !important;
    }
    .info-new_slick ,.title-container {
        max-width: 90% !important;
    }
    .Rotate-slick.Us {
        height: 110vh !important;
    }
    .marker-contact, .call-contact {
        margin-left: 10px !important;
    }
    .Slicks-pm .info-slick.Us {
        top: 800px !important;
    }
    .pm-inf_sk.ma svg {
        width: 50px !important;
        height: 58px !important;
    }
    .img-values svg {
        width: 50px !important;
        height: 58px !important;
    }
    .footer-dc > div {
        margin-top: 10px;
        font-size: 14px !important;
    }
    .Mision-vision_pm .info-vision, .Mision-vision_pm .info-mision, .Mision-vision_pm .info-promise_value, .Mision-vision_pm .info-propose, .Mision-vision_pm .square-mision, .Mision-vision_pm .square-vision, .Mision-vision_pm .square-promise_value, .Mision-vision_pm .square-propose {
        max-width: 260px !important;
        max-height: 250px !important;
    }
    .Products-Quote .products-default {
        grid-template-columns: repeat(1,1fr) !important;
        text-align: -webkit-center !important;
    }
    .objectives-sosten svg {
        width: 50px !important;
        height: 58px !important;
    }
    .info-sl-sbl p {
        max-width: 300px !important;
    }
    .play-known .img-distributor img,  .play-known .img-video_known .img img {
        height: 100% !important;
    }
    .card-work ,.container-grid.work .card-work{
        max-width: 320px !important;
        max-height: 400px !important;
    }
    .container-grid.elipse .box-container .container-accordion {
        max-width: 80% !important;
    }
    .card-body {
        padding: 20px 10px 20px 10px !important;
    }
    .new-slick.work.sbl img {
        height: 390px !important;
    }
    .Container-sb_pm .sb_pm .header-title p {
        font-size: 12px !important;
    }
    .new-slick.work.tablet.xs .card-body .text {
        margin-bottom: 0px !important;
    }
    .container-grid.work .new-slick.work.tablet.xs .card-work {
        height: 340px !important;
    }
    .container-grid.work .new-slick.work.tablet.xs .card-body {
        top: -133px !important;
    }
    .card-body {
        top: -152px !important;
    }
    .info-informs p {
        font-size: 12px !important;
    }
    .box-informs-pdf {
        padding: 0px 10px !important;
    }
    .box-informs-pdf p {
        font-size: 10px !important;
    }
    .box-informs-pdf svg {
        width: 30px !important;
    }
    .post-pet-sbl.activity .img-new_slick.activity ,.post-pet-sbl.activity .square-pet-sbl.activity {
        max-width: 250px !important;
        max-height: 300px !important;
    }
    .container-grid.videos .play-button_pm.activity {
        top: 250px !important;
        left: 150px !important;
    }
    .container .container-grid.why .play-button_pm {
        top: 200px !important;
        right: 10px !important;
    }
    .container .container-grid.clients-page .info-client-footer {
        max-width: 100% !important;
    }
    .Known-pm.home .container-products_business div {
        max-width: 90% !important;
    }
}

@media screen and (max-height: 896px) and (max-width: 430px) {
    .Products-populate_pm .products-default {
        grid-template-columns: repeat(1, 1fr) !important;
    }
    .play-button_pm {
        width: 100px !important;
        height: 100px !important;
    }
}

@media screen and (max-height: 780px) and (max-width: 484px) {
    .Home .content-slick-home.xs {
        height: 62vh !important;
    }
    .Rotate-slick.Us.xs {
        height: 115vh !important;
    }
    .Rotate-slick.Us.xs::after {
        background-position: 0px -80px !important;
    }
    .Rotate-slick.Us.Sb.ls::after {
        top: 420px !important;
        left: -300px !important;
    }
    .Rotate-slick.Us.Wu.ls::after {
        top: 520px !important;
        left: -400px !important;
    }
    .Rotate-slick.Us.New.ls::after {
        top: 520px !important;
        left: -400px !important;
    }
    .Rotate-slick.Us.Ctt.ls::after {
        top: 520px !important;
        left: -480px !important;
    }
}

@media screen and (max-height: 896px) and (max-width: 414px) {
    .biof {
        top: 130px !important;
        left: 120px !important;
    }
    .info-slick {
        top: 600px !important;
        left: 0px !important;
    }
    .info-slick.second {
        top: 530px !important;
        left: 10px !important;
    }
    .Elipse {
        top: 0px !important;
    }

    .biof img {
        width: 100px !important;
        height: 70px !important;
    }
    .Home .content-slick-home {
        height: 64vh !important;
    }
    .Detail-Product_pm.movil .square-products {
        min-width: 210px !important;
        min-height: 210px !important;
    }
    .container-gallery {
        grid-template-columns: repeat(1,1fr) !important;
    }
    .gallery-item {
        grid-column: 1/2 !important;
    }
    .img-detail_product {
        height: 250px !important;
    }
    .container-detail_product .square-products {
        right: 0px !important;
    }
    .Detail-Product_pm.movil .container-detail_product .img-product {
        max-width: 280px !important;
    }
    .Detail-Product_pm.movil .container-detail_product .img-product {
        max-height: 250px !important;
    }
    .Known-pm.home .container-products_business  div p.know-lines {
        margin-left: 10px !important;
    }
}

@media screen and (max-height: 667px) and (max-width: 375px) {
    .Home .content-slick-home.md.movil {
        height: 60vh !important;
    }
    .info-slick {
        top: 580px !important;
        left: 0px !important;
    }
    .Elipse.movil {
        left: 70px !important;
    }
    .Elipse.movil img {
        width: 200px !important;
        height: 200px !important;
    }
}

@media screen and (max-height: 872px) and (max-width: 375px) {
    .Rotate-slick.Us.ls {
        height: 108vh !important;
    }
    .Rotate-slick.Us.Main .info-slick.Us.ls {
        top: 700px !important;
        left: -125px !important;
    }
    .Rotate-slick.first .info-slick {
        top: 600px !important;
        left: -10px !important;
    }
    .Rotate-slick.first {
        top: -335px !important;
    }
}

@media screen and (max-height: 568px) and (max-width: 320px) {
    .Rotate-slick.ss::after {
        height: 88vh !important;
    }
    .Rotate-slick.Us.Main.ls::after {
        top: 220px !important;
        left: -420px !important;
    }
    .info-slick.second {
        top: 450px !important;
        left: 10px !important;
    }
    .Rotate-slick.Us.Main .info-slick.Us.ls {
        top: 460px !important;
        left: -100px !important;
    }
    .Rotate-slick.Us.Sb.ls::after {
        top: 220px !important;
        left: -300px !important;
    }
    .Rotate-slick.Us.Wu.ls::after {
        top: 320px !important;
        left: -420px !important;
    }
    .Rotate-slick.Us.Cl.ls::after {
        top: 520px !important;
        left: -200px !important;
    }
    .Rotate-slick.Us.New.ls::after {
        top: 320px !important;
        left: -400px !important;
    }
    .Rotate-slick.Us.Ctt.ls::after {
        top: 320px !important;
        left: -550px !important;
    }
    .Home .content-slick-home.movil.ss {
        height: 90vh !important;
    }
    .Elipse.movil {
        top: 0px !important;
        left: 60px !important;
    }
    .Elipse.movil img {
        width: 180px !important;
        height: 180px !important;
    }
    .biof {
        top: 90px !important;
        left: 110px !important;
    }

    .biof img {
        width: 90px !important;
        height: 60px !important;
    }
    .Rotate-slick.Us.movil {
        height: 130vh !important;
    }
}

@media screen and (max-height: 851px) and (max-width: 380px) {
    .Home .content-slick-home {
        height: 89vh !important;
    }
    .Detail-Product_pm.movil .square-products {
        min-width: 210px !important;
        min-height: 210px !important;
    }
    .img-detail_product {
        height: 250px !important;
    }
    .container-detail_product .square-products {
        right: 0px !important;
    }
    .Detail-Product_pm.movil .container-detail_product .img-product {
        max-width: 280px !important;
    }
    .Detail-Product_pm.movil .container-detail_product .img-product {
        max-height: 250px !important;
    }
    .Known-pm.home .container-products_business  div p.know-lines {
        margin-left: 0px !important;
    }
}


@media screen and (max-height: 1000px) and (max-width: 375px) {
    .Elipse.movil {
        top: 10px !important;
        left: 75px !important;
    }
    .Elipse.movil img {
        width: 180px !important;
        height: 180px !important;
    }
    .biof {
        top: 100px !important;
        left: 120px !important;
    }
    .biof img {
        width: 90px !important;
        height: 60px !important;
    }
    .Known-pm.home .container-products_pm img {
        max-width: 126px !important;
    }
    .container-products_business {
        display: block !important;
        grid-column: none !important;
    }
    .button-cat button, .button-cat a {
        max-width: 60% !important;
        margin: 0 auto !important;
    }
    .Known-pm .container-known_business p.know-lines.movil {
        margin-left: 0px !important;
        margin-bottom: 10px !important;
    }
    .Known-pm .container-known_business div {
        margin-top: 20px !important;
    }
    .info-carbajal img {
        max-width: 80% !important;
    }
    .img-about_us .img img,.square-img-card.right-up, .img-card, .square-img-card.right-up, .News-pm_us .img-new_slick_us, .News-pm_us .img-new_slick, .img-distributor, .img-video_known .img, .tablet .img-new_slick, .img-carbajal.about, .card-about .img-about_us {
        max-width: 260px !important;
        max-height: 160px !important;
    }
    .container-grid.first .square-img-card, .square-sl-sbl, .square-distributor, .container-known_video .play-known .square, .tablet .container-news .square-slick, .wc .square-carbajal, .card-about .square.us, .container-grid .play-known .square{
        max-width: 260px !important;
        max-height: 160px !important;
        top: 55px !important;
    }
    .container-known_video .play-known .square {
        top: -10px !important;
    }
    .play-button_pm {
        top: 40px !important;
        right: -30px !important;
    }
    .wc .square-carbajal  {
        top: 20px !important;
    }
    .card-about .square.us {
        top: -20px !important;
    }
    .container-vision, .container-mision, .container-promise-value, .container-propose {
        margin-left: 56px !important;
    }
    .container-grid .play-known .square {
        top: 80px !important
    }
    .container-grid.sbl .play-known .play-button_pm {
        top: 140px !important;
    }
    .card-work, .container-grid.work .card-work {
        max-width: 280px !important;
        max-height: 360px !important;
    }
    .container {
        padding-bottom: 70px !important;
    }
    .container-grid.clients-page div#exportation,  .container-grid.clients-page div#agroindustrial{
        padding-top: 0px !important;
    }
    .client-info {
        position: relative;
        top: -40px !important;
    }
    .container .container-grid.why .play-button_pm {
        top: 150px !important
    }
    .info-post-activity p {
        max-width: 60% !important;
        margin-left: 10px !important;
    }
}

@media screen and (max-height: 838px) and (max-width: 414px) {
    .Home .content-slick-home {
        height: 90vh !important;
    }
}

@media screen and (max-height: 800px) and (max-width: 600px) {
    .Rotate-slick.Us {
        height: 115vh !important;
    }
}

@media screen and (max-height: 736px) and (max-width: 414px) {
    .Rotate-slick.Us {
        height: 115vh !important;
    }
    .Elipse {
        left: 75px !important;
    }
    .Slicks-pm .info-slick.Us {
        left: 0px !important;
    }
    .Rotate-slick.Us.Main .info-slick.Us.ls {
        top: 690px !important;
        left: -125px !important;
    }
    .Rotate-slick.first {
        top: -335px !important;
    }
}

@media screen and (max-height: 851px) and (max-width: 393px) {
    .Home .content-slick-home.movil {
        height: 87vh !important;
    }
    .Rotate-slick.xs::after {
        background-size: contain !important;
    }
}

@media screen and (max-height: 667px) and (max-width: 375px) {
    .slick-list .Rotate-slick.Us {
        top: -600px !important;
    }
    .Rotate-slick.Us::before {
        min-height: 164vh !important;
    }
    .Slicks-pm.Us .slick-slider.slick-initialized .slick-slide {
        height: 55vh !important;
    }
    .Slicks-pm .Rotate-slick.Us .info-slick.Us {
        top: 700px !important;
    }
    .Slicks-pm .Rotate-slick.Us::after {
        background-position: top !important;
    }
    .Rotate-slick.Us.Main .info-slick.Us.ls {
        top: 590px !important;
        left: -125px !important;
    }
    .Rotate-slick.Us.ls {
        height: 110vh !important;
    }
    .Rotate-slick.Us.Main.ls::after {
        top: 230px !important;
        left: -550px !important;
    }
    .Rotate-slick.Us.Sb.ls::after {
        top: 240px !important;
    }
    .Rotate-slick.Us.Cl.ls::after {
        top: 430px !important;
    }
    .Rotate-slick.Us.New.ls::after {
        top: 330px !important;
    }
    .Rotate-slick.Us.Ctt.ls::after {
        top: 330px !important;
        left: -550px !important;
    }
}

@media screen and (max-height: 740px) and (max-width: 360px) {
    .Home .content-slick-home.movil {
        height: 87vh !important;
    }
    .Rotate-slick.xs::after {
        background-size: contain !important;
    }
}

@media screen and (max-height: 656px) and (max-width: 320px) {
    .Home .content-slick-home.movil {
        height: 87vh !important;
    }
    .Rotate-slick.Us.Main .info-slick.Us.ls {
        top: 460px !important;
        left: -110px !important;
    }
    .Rotate-slick.Us.ls {
        height: 122vh !important;
    }
    .Slicks-pm .Rotate-slick.Us .info-slick.Us {
        top: 550px !important;
        left: 20px !important;
        max-width: 40% !important;
    }
    .info-slick {
        top: 550px !important;
        left: -10px !important;
    }
    .Rotate-slick::before {
        height: 160vh !important;
    }
    .Rotate-slick::after {
        height: 86vh !important;
    }
    .img-carbajal.about ,.container-grid.first .square-img-card, .square-sl-sbl, .square-distributor, .container-known_video .play-known .square, .wc .square-carbajal, .card-about .square.us, .container-grid .play-known .square {
        max-width: 220px !important;
        top: -20px !important;
    }
    .tablet .container-news .square-slick {
        max-width: 220px !important;
    }
    .container-vision, .container-mision, .container-promise-value, .container-propose {
        margin-left: 40px !important;
    }
    .Mision-vision_pm .info-vision, .Mision-vision_pm .info-mision, .Mision-vision_pm .info-promise_value, .Mision-vision_pm .info-propose, .Mision-vision_pm .square-mision, .Mision-vision_pm .square-vision, .Mision-vision_pm .square-promise_value, .Mision-vision_pm .square-propose {
        width: 245px !important;
    }
    .wc .square-carbajal {
        top: 20px !important;
    }
    .post-sl-sbl .square-sl-sbl {
        top: 50px !important
    }
    .container-grid .play-known .square {
        top: 90px !important;
    }
    .footer-dc {
        padding-top: 0px !important;
        padding-bottom: 50px !important;
    }
    .container-header-rs .Logo-pm {
        grid-column: 1/10 !important;
    }
    .container-grid.first .square-img-card {
        top: 50px !important;
        border-radius: 30px !important;
    }
    .Known-pm.home .container-products_pm img {
        max-width: 116px !important;
    }
}

@media screen and (min-height: 656px) and (max-width: 320px) {
    .Known-pm.home .container-products_pm img {
        max-width: 116px !important;
    }
}

@media screen and (max-width: 374px) {
    .Rotate-slick.first .info-slick.second {
        top: 500px !important;
        left: -10px !important;
    }
    .Rotate-slick.first {
        top: -335px !important;
    }
    .Slicks-pm ul.slick-dots {
        width: 24% !important;
        right: 5px !important;
        top: 150px !important;
     }
}