.News-pm {
    position: relative;
    margin: 0 auto;
    height: auto;
    background: #f9f9f9;
}

.News-pm_us {
    position: relative;
    height: auto;
    background: #FFFFFF;
    overflow: hidden;
}

.square-new {
    position: absolute;
    overflow: hidden;
    top: 0px;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-image: url('../images/img/squarenews.webp');
    background-repeat: no-repeat;
}

.square-stb {
    position: absolute;
    overflow: hidden;
    top: 0px;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-image: url('../images/img/squarestb.webp');
    background-repeat: no-repeat;
}

.square-us {
    position: relative;
    max-width: 1708.8px;
    height: 1419.8px;
    top: 210px;
    right: 0px;
    background: rgba(0, 82, 134, 0.2);
    border-radius: 191px;
    transform: rotate(-21.12deg);
}

.square-sl-sbl {
    position: absolute;
    top: 40px;
    width: 578.67px;
    height: 393.04px;
    right: 0px;
    z-index: 0;
    background: rgba(141, 183, 46, 0.15);
    border-radius: 50px;
    transform: rotate(172.1deg);
}

.square-img-card {
    position: absolute;
    top: 0px;
    width: 667.49px;
    height: 453.04px;
    right: -115px;
    z-index: 0;
    background: rgba(141, 183, 46, 0.15);
    border-radius: 50px;
    transform: rotate(172.1deg);
}

.square-img-card.right-up {
    width: 578.45px;
    height: 393.04px;
    right: 10px;
    background: rgba(255, 255, 255, 0.14);
    transform: rotate(9.05deg) !important;
}

.square-pet-sbl {
    position: absolute;
    top: 40px;
    width: 578.67px;
    height: 393.04px;
    left: 120px;
    z-index: 0;
    background: rgba(141, 183, 46, 0.21);
    border-radius: 50px;
    transform: rotate(8.73deg);
}

.square-pet-sbl.activity {
    position: absolute;
    top: 140px !important;
    width: 437.67px;
    height: 471.04px;
    left: 100px !important;
    z-index: 0;
    background: #5B8D4B;
    border-radius: 50px;
    transform: rotate(8.73deg);
}

.elipse-work {
    position: absolute;
    overflow: hidden;
    z-index: 1;
    width: 100%;
    left: 0;
    bottom: -820px;
    height: 100%;
}

.elipse-work-videos {
    position: absolute;
    overflow: hidden;
    z-index: 1;
    bottom: 0;
    background-position: bottom left;
    width: 1708.8px;
    height: 1489.8px;
    background-image: url('../images/img/elipsevideo.webp');
    background-repeat: no-repeat;
}

.post-pet-sbl {
    grid-column: 1/4;
    position: relative;
}

.post-pet-sbl.activity {
    padding: 0px 0px 300px 10px;
}

.tree-new {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 10px;
}

.tree-new.news img {
    width: 240px;
    opacity: 0.5;
    position: relative;
    top: 150px;
    transform: rotate(138.73deg);
}

.tree-pm.banner img {
    width: 240px;
    opacity: 0.5;
    position: relative;
    top: 100px;
    transform: rotate(-128.73deg);
}

.tree-new.contact-info {
    position: absolute;
    left: 0px !important;
}

.tree-new.contact-info img {
    width: 240px;
    opacity: 0.5;
    top: -140px !important;
    position: relative;
    transform: rotate(148.73deg);
}

.new-slick.work {
    grid-column: 4/8;
    position: relative;
    z-index: 1001;
}

.new-slick.work.activity {
    grid-column: 2/7 !important;
    right: 100px;
}

.title-new {
    width: 204px;
    position: relative;
    z-index: 1;
}

.title-new h4 {
    color: #005286;
    font-size: 50px;
    font-family: 'Amble-bold';
}

.container-news {
    display: grid !important;
    position: relative;
    z-index: 1;
    align-items: center;
    grid-template-columns: repeat(7,1fr);
    margin-top: 200px;
    padding-bottom: 50px;
}

.container-detail_new .slick-dots {
    width: 70% !important;
}

.new-slick .slick-dots {
    bottom: 90px !important;
    background: transparent !important;
    text-align: left !important;
    z-index: 2500 !important;
    padding: 0 !important;
    width: 100px !important;
    left: 265px;
}

.new-slick .slick-dots li {
    border: none !important;
}

.new-slick .slick-dots ul li button::before {
    display: none;
}

.new-slick .slick-dots ul li button {
    font-size: 16px !important;
    color: #005286;
    font-family: 'Amble';
}

.new-slick.sbl .slick-dots ul li button {
    color: #32711E;
}

.new-slick .slick-dots ul {
    display: flex;
}

.new-slick .slick-dots ul li.slick-active, .new-slick .slick-dots ul li {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
}

.new-slick .slick-dots ul li.slick-active {
    background: #fff;
    border: 1px solid #FFF !important;
}

.new-slick.sbl .slick-dots ul li.slick-active {
    background: #fff;
    border: 1px solid #FFF !important;
}

.new-slick .slick-dots ul li {
    background: transparent;
    border: 1px solid #005286 !important;
}

.new-slick.sbl .slick-dots ul li {
    background: transparent;
    border: 1px solid #32711E !important;
}

.new-slick .slick-arrow {
    display: none !important;
}

.new-slick.slick-slider {
    height: auto !important;
}

.slick-slide div {
    border: none !important;
    outline: none !important;
}

.info-new_slick {
    grid-column: 2/4;
}

.info-new_slick h4{
    color: #005286;
    font-size: 50px;
    font-family: 'Amble-bold';
    margin-top: 20px;
    line-height: 97.2%;
    margin-bottom: 20px;
    width: 262px;
}

.info-new_slick h6{
    color: #009FE3;
    font-size: 28px;
    font-family: 'Amble-bold';
    margin-top: 20px;
    line-height: 97.2%;
    margin-bottom: 20px;
    width: 380px;
}

.info-new_slick p{
    width: 350px;
    margin-top: 30px;
    font-family: 'Amble-light';
    font-size: 14px;
    color: #4D4D4D;
    margin-bottom: 10px;
}

.info-new_slick a {
    font-size: 14px;
    color: #4D4D4D;
    font-family: 'Amble-bold';
    text-decoration: underline;
}

.post-new_slick {
    position: relative;
    top: 70px;
    grid-column: 4/8;
    padding-bottom: 200px;
    display: flex;
    justify-content: center;
}


.post-new_slick_us {
    position: relative;
    grid-column: 4/8;
    padding-bottom: 120px;
    display: flex;
    justify-content: flex-start;
}

.square-slick {
    position: absolute;
    top: 40px;
    width: 578.45px;
    height: 393.04px;
    z-index: 0;
    background: rgba(0, 82, 134, 0.2);
    border-radius: 50px;
    transform: rotate(9.05deg);
}

.img-new_slick {
    position: relative;
    top: 40px;
    left: 40px;
    width: 579px;
    height: 385px;
}

.img-new_slick.activity {
    position: relative;
    top: 40px;
    left: 40px;
    width: 437px;
    height: 471px;
}

.img-card {
    position: relative;
    width: 814.15px;
}

.img-card::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 101%;
    height: 100%;
    background: rgba(0, 82, 134, 0.2);
    content: '';
    border-radius: 50px;
}

.img-card img {
    border-radius: 50px;
    object-fit: cover;
    height: 100%;
}

.img-work_slick {
    position: relative;
    top: 40px;
    left: 40px;
    width: 602px;
    height: 384px;
}

.img-new_slick_us {
    width: 560px;
    height: 385px;
    position: relative;
    top: 40px;
    left: 40px;
}

.img-new_slick::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 82, 134, 0.2);
    content: '';
    border-radius: 50px;
}

.img-new_slick_us::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 82, 134, 0.2);
    content: '';
    border-radius: 50px;
}

.img-new_slick img {
    border-radius: 50px;
    width: 100%;
    object-position: 0px;
    object-fit: cover;
    height: 100%;
}

.img-new_slick_us img {
    border-radius: 50px;
    width: 100%;
}

.Footer-news {
    position: relative;
    width: 100%;
    max-width: 1440px;
    z-index: 2000;
    overflow: hidden;
    padding-bottom: 80px;
    left: 0;
}

.Footer-news_us {
    position: relative;
    width: 100%;
    margin: 0 auto;
}

.squeare-footer_new {
    position: absolute;
    z-index: -1;
    left: -110px;
    width: 720.08px;
    height: 471.87px;
    background: rgba(0, 159, 227, 0.27);
    border-radius: 50px;
    transform: rotate(8.73deg);
}

.img-footer_new {
    position: relative;
    width: 570px;
    height: 440px;
}

.img-footer_new::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 82, 134, 0.2);
    content: '';
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.img-footer_new img {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.container-footer_news {
    display: grid;
    grid-template-columns: repeat(7,1fr);
    margin-top: 100px;
}

.container-footer_news_us,.container-title-new, .container-certifications {
    display: grid;
    grid-template-columns: repeat(7,1fr);
}

.container-certifications {
    margin-top: 80px;
}

.img-footer_news {
    grid-column: 1/4;
}

.info-footer_news {
    grid-column: 4/8;
    display: flex;
    justify-content: center;
}


.img-footer_news div {
    display: flex;
    justify-content: center;
}

.info-footer_news h6 {
    width: 516px;
    font-family: 'Amble-bold';
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 59px;
    color: #009FE3;
}

.info-footer_news p {
    width: 350px;
    margin-top: 30px;
    font-family: 'Amble-light';
    font-size: 14px;
    color: #4D4D4D;
}

.companies {
    margin-top: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.companies div, .companies-second div {
    margin-right: 30px;
}

.companies .img-company-sgs {
    position: relative;
    width: 68px;
    height: 67px;
    background-image: url('../images/img/sgs.webp');
    background-size: cover;
    background-color: #D1EBFC;
    background-blend-mode: darken;
}

.companies .img-company-sgsiso {
    position: relative;
    width: 68px;
    height: 67px;
    background-image: url('../images/img/iso.webp');
    background-size: cover;
    background-color: #D1EBFC;
    background-blend-mode: darken;
}

.companies .img-company-brc {
    position: relative;
    width: 67px;
    height: 58px;
    background-image: url('../images/img/brcpackaging.webp');
    background-size: cover;
    background-color: #D1EBFC;
    background-blend-mode: darken;
}

.companies_us .img-company-abe, .companies-second_us .img-company-abe {
    position: relative;
    width: 140px;
    height: 140px;
    right: 12px;
    background-color: #F9F9F9;
    background-image: url('../images/img/selloemprendedor.webp');
    background-size: cover;
    background-blend-mode: darken;
}

.companies-second_us .img-company-gptw21 {
    position: relative;
    width: 109px;
    height: 189px;
    right: 30px;
    background-image: url('../images/img/gptw2021.png');
    background-size: cover;
    position: relative;
}

.companies-second .img-company-gp {
    position: relative;
    width: 60px;
    height: 60px;
    background-image: url('../images/img/greatperu.webp');
    background-size: cover;
    background-color: #D1EBFC;
    background-blend-mode: darken;
}

.companies-second .img-company-gp2 {
    position: relative;
    width: 60px;
    height: 60px;
    background-image: url('../images/img/greatperu2.webp');
    background-size: cover;
    background-color: #D1EBFC;
    background-blend-mode: darken;
}

.companies-second .img-company-gpl {
    position: relative;
    width: 183px;
    height: 57px;
    background-image: url('../images/img/greatplaces.webp');
    background-size: cover;
    background-color: #D1EBFC;
    background-blend-mode: darken;
}

.companies .img-company img {
    width: 100%;
    height: 100%;
}

.companies-second {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.companies-second .img-company img.large {
    width: 183px;
    height: 67px;
}

.img-footer_news_us {
    display: flex;
    grid-column: 2/5;
}

.info-footer_news_us {
    grid-column: 5/8;
    display: flex;
    justify-content: flex-start;
}

.container-footer_news_us {
    padding-top: 20px;
}

.info-footer_news_us h6 {
    color: #32711E;;
    font-size: 50px;
    font-family: 'Amble-bold';
    margin-top: 20px;
    line-height: 97.2%;
    margin-bottom: 50px;
    width: 280px;
}


.info-footer_news_us div.span {
    color: #8DB72E;
    font-size: 30px;
    width: 383px !important;
    font-family: 'Amble-bold';
    margin-top: 40px;
    line-height: 97.2%;
    margin-bottom: 20px;
}

.info-footer_news_us p {
    font-family: 'Amble-light';
    font-style: normal;
    width: 504px;
    margin-top: 40px;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #4D4D4C;
    opacity: 0.8;
}

.Certifications {
    background: #f9f9f9;
    position: relative;
    z-index: 99999;
    height: auto;
    padding-bottom: 40px;
}

.info-certifications {
    grid-column: 2/5;
    display: flex;
}

.certifications-companies {
    grid-column: 5/8;
    display: flex;
}

.info-certifications div {
    width: 512px;
}

.info-certifications h6 {
    color: #32711E;;
    font-size: 50px;
    font-family: 'Amble-bold';
    margin-top: 20px;
    line-height: 97.2%;
    margin-bottom: 25px;
    width: 563px;
}

.info-certifications span {
    color: #8DB72E;
    font-size: 30px;
    font-family: 'Amble-bold';
    margin-top: 20px;
    line-height: 97.2%;
    margin-bottom: 20px;
}

.info-certifications p {
    font-family: 'Amble-light';
    font-style: normal;
    width: 431px;
    margin-top: 30px;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #4D4D4C;
}

.companies_us {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.companies_us div, .companies-second_us div {
    margin-right: 70px;
}

.companies_us .img-company-sgs {
    position: relative;
    width: 103px;
    height: 101px;
    background-image: url('../images/img/sgs.webp');
    background-size: cover;    
}

.companies_us .img-company-sgiso {
    position: relative;
    width: 103px;
    height: 101px;
    background-image: url('../images/img/iso.webp');
    background-size: cover;    
}

.companies_us .img-company-brc {
    position: relative;
    width: 121px;
    height: 101px;
    background-image: url('../images/img/brcpackaging.webp');
    background-size: contain;
    position: relative;   
    background-repeat: no-repeat;         
}

.companies-second_us .img-company-gp {
    position: relative;
    width: 109px;
    margin-bottom: 30px;
    height: 109px;
    background-image: url('../images/img/greatperu.webp');
    background-size: cover;
    position: relative; 
}

.companies-second_us .img-company-gplast {
    position: relative;
    width: 109px;
    margin-bottom: 30px;
    height: 109px;
    background-image: url('../images/img/greatplacelast.webp');
    background-size: cover;
    position: relative; 
}

.companies-second_us .img-company-gpl {
    position: relative;
    width: 109px;
    height: 109px;
    margin-bottom: 30px;
    background-image: url('../images/img/greatplaces.webp');
    background-size: cover;
    position: relative;
}

.companies-second_us .img-company-gpw {
    position: relative;
    width: 109px;
    height: 109px;
    margin-bottom: 30px;
    background-image: url('../images/img/grateplacework.webp');
    background-size: cover;
    position: relative;
}

.companies_us .img-company img {
    width: 100%;
    height: 100%;
}

.companies-second_us {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap !important;
    align-items: center;
}

.companies-second_us div {
    margin-right: 30px;
}

.companies-second_us .img-company img.large {
    width: 183px;
    height: 67px;
}

.card-work {
    height: 537px;
    width: 437px;
    margin-left: 400px;
}

.container-grid.work .new-slick.work.tablet .card-work{
    height: 420px;
}

.container-grid.work .new-slick.work.tablet .header-work{
    height: 100% !important;
}

.container-grid.work .new-slick.work.tablet .card-body {
    padding: 35px 35px 35px 35px;
}

.card-work {
    background: #fff;
    border-top-right-radius: 26px;
    border-top-left-radius: 26px;
    border-bottom-left-radius: 26px;
    border-bottom-right-radius: 26px;
}

.card-work img {
    border-top-right-radius: 26px;
    border-top-left-radius: 26px;
}

.header-work {
    position: relative;
}

.header-work.sbl img {
    width: 100%;
    height: 460px;
}

.header-work::after{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 82, 134, 0.2);
    content: '';
    border-radius: 26px;
}

.card-body {
    position: relative;
    top: -140px;
    z-index: 1;
    padding: 35px 35px 10px 35px;
    background: #fff;
    border-bottom-left-radius: 26px;
    border-bottom-right-radius: 26px;
}

.card-body h6 {
    font-family: 'Amble-bold';
    color: #005286;
    font-size: 18px;
}

.card-body p {
    font-family: 'Amble-light';
    color: #4c4c4c;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.card-body .text {
    margin-bottom: 40px;
}

.card-body a , .card-body button{
    text-decoration: none;
    font-family: 'Amble-bold';
    color: #005286;
    font-size: 14px;
    margin-top: 20px;
    padding: 10px 30px;
    background: transparent;
    border: 1px solid #005286;
    border-radius: 26px;
    cursor: pointer;
    outline: none;
}

.new-slick.work .slick-dots {
    bottom: 205px !important;
    background: transparent !important;
    z-index: 2500 !important;
    padding: 0 !important;
    left: -520px !important;
}

.new-slick.work.activity .slick-dots {
    bottom: 25px !important;
    background: transparent !important;
    z-index: 2500 !important;
    padding: 0 !important;
}

.container-known_video .player-wrapper {
    position: absolute;
    width: 100%;
    right: 0%;
    height: 65vh;
    top: 3%;
}

.container-grid.sbl .player-wrapper {
    position: absolute;
    width: 80%;
    right: 10%;
    height: 74vh;
    top: 3%;
}

.new-slick.work.activity .slick-dots ul li {
    border: 1px solid #fff !important;
}

.new-slick.work.activity .slick-dots ul li.slick-active button {
    color: #33711D !important;
}

.new-slick.work.activity .slick-dots ul li button {
    color: #fff !important;
}

.new-slick.work.sbl .slick-dots {
    bottom: 130px !important;
    background: transparent !important;
    z-index: 2500 !important;
    padding: 0 !important;
    left: -580px;
    position: absolute !important;
}

.certifications-companies h6.companies-title, .certifications-companies h6.companies-title_second {
    font-family: 'Amble-bold';
    color: #4c4c4c;
    font-size: 25px;
}

.certifications-companies h6.companies-title {
    margin-top: 30px;
}

.certifications-companies h6.companies-title_second {
    margin-top: 20px;
    margin-bottom: 25px;
}

.container-known_business {
    display: grid;
    grid-template-columns: repeat(7,1fr);
    background: #f9f9f9;
    margin: 0 auto;
}

.container-title-new h6 {
    grid-column: 2/4;
    font-family: 'Amble-bold';
    color: #32711E;
    font-size: 50px;
    width: 403px;
}

.container-products_business {
    grid-column: 1/8;
    display: flex;
    justify-content: space-between;
    margin: 60px auto;
    width: 960px;
}

.container-products_business img{
    border-radius: 50px;
}

.us-values_pm {
    background: #fff;
    margin: 0 auto;
}

.title-values {
    grid-column: 2/4;
    margin-top: 40px;
    margin-left: 0px;
    display: flex;
    justify-content: center;
    color: #009FE3;
    width: 301px;
    font-family: 'Amble';
    font-size: 50px;
}

.img-values {
    display: flex;
    max-width: 1240px;
    margin: 0 auto;
    justify-content: space-between;
    padding: 70px;
    flex-wrap: wrap;
    text-align: center;
}

.img-values p {
    font-family: 'Amble-light';
    font-size: 14px;
    margin-top: 10px;
    width: 112px;
    color: #4d4d4d;
}

.news-search {
    margin: 0 auto;
    background: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
}

.news-search h2, .news-search .text {
    text-align: center;
    margin-top: 100px;
}

.news-search h2 {
    font-family: 'Amble-bold';
    color: #32711E;
    font-size: 50px;
}

.news-search  .text {
    width: 600px;
    margin: 20px auto;
    font-family: 'Amble-light';
    font-size: 14px;
    color: #4D4D4C;
}

.search-date {
    max-width: 768px;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(9,1fr);
    margin: 0px auto;
    grid-gap: 20px;
}

.input-select {
    width: 100%;
    margin-top: 20px;
}

.s1 {
    grid-column: 1/4;
}

.s2 {
    grid-column: 4/7;
}

.s3 {
    grid-column: 7/10;
}

.css-1ej2tn0-control:hover, .css-1ej2tn0-control {
    border-color: none !important;
    box-shadow: none !important;
}

.container-gallery {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 40px;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 80px;
}

.gallery-item { 
    position: relative;
    font-family: 'Amble-light';
}

.gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
}

.gallery-item::after {
    position: absolute;
    content: '';
    background: linear-gradient(162.44deg, rgba(0, 82, 134, 0.5) 23.72%, rgba(0, 82, 134, 0) 95.92%);
    border-radius: 30px;
    width: 100%;
    height: 100%;
    z-index: 2;
    left: 0;
    object-fit: cover;
}

.gallery-info {
    position: relative;
    z-index: 100;
    padding: 20px;
}

.gallery-item:nth-child(1) , 
.gallery-item:nth-child(2) ,
.gallery-item:nth-child(5) , 
.gallery-item:nth-child(6) ,
.gallery-item:nth-child(7) , 
.gallery-item:nth-child(8) ,
.gallery-item:nth-child(11),
.gallery-item:nth-child(12)
{
    grid-column-start: span 1;
    height: 300px;
}

.gallery-item:nth-child(3), 
.gallery-item:nth-child(4), 
.gallery-item:nth-child(9),
.gallery-item:nth-child(10),
.gallery-item:nth-child(15),
.gallery-item:nth-child(16) 
{
    grid-column-start: span 2;
    height: 300px;
}

.gallery-item .gallery-info {
    position: absolute;
    top: 0;
    color: #fff;
}

.gallery-date {
    font-size: 12px;
}

.gallery-default {
    font-size: 14px;
}

.gallery-title {
    font-family: 'Amble-bold';
    font-size: 23px;
    margin-top: 10px;
}

.btn-new {
    margin-top: 20px;
}

.btn-new a {
    font-family: 'Amble-bold';
    color: #fff;
    background: transparent;
    margin-top: 20px;
    border: 1px solid #fff;
    padding: 12px 16px;
    border-radius: 30px;
    cursor: pointer;
    outline: none;
    text-decoration: none;
}

.container-view_journalists {
    max-width: 1200px;
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    display: grid;
    grid-template-columns: repeat(6,1fr);
    border-radius: 30px;
    top: -40px;
    position: relative;
}

.info-view_journalists {
    grid-column: 1/3;
    padding: 5px 0px 30px 20px;
}

.info-view_journalists h2 {
    font-family: 'Amble-bold';
    color: #005286;
    text-align: left;
    font-size: 25px;
    width: 217px;
}

.info-view_journalists p {
    font-family: 'Amble-light';
    font-size: 14px;
    margin-top: 10px;
    color: #4d4d4d;
    margin-bottom: 30px;
}

.info-view_journalists a {
    background: rgba(118, 118, 118, 0.06);
    color: #005286;
    border: 1px solid #005286;
    font-family: 'Amble-bold';
    padding: 12px 25px;
    border-radius: 30px;
    text-decoration: none;
    margin-top: 10px;
    cursor: pointer;
    outline: none;
}

.img-view_journalists {
    grid-column: 3/7;
    position: relative;
}

.img-view_journalists::after {
    position: absolute;
    background: rgba(0, 82, 134, 0.2);
    border-radius: 0px 30px 30px 0px;
    z-index: 1000;
    content: '';
    width: 100%;
    object-fit: cover;
    top: 0px;
    height: 100%;
    left: 0;
}

.img-view_journalists img {
    position: absolute;
    height: 100%;
    width: 100%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

.Detail-new_pm {
    position: relative;
    height: 50vh;
    background-size: cover;
    width: 100%;
    margin: 0 auto;
    background-repeat: no-repeat;
}

.Detail-new_pm img {
    width: 100%;
    object-fit: cover;
    background-repeat: no-repeat;
    height: 100%;
}

.Detail-new_pm.gallery-paper {
    background-image: url('../images/img/galeriaperiodisticabanner.webp') !important;
}

.Detail-new_pm::after {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 82, 134, 0.2);
    content: '';
}

.container-detail_new {
    margin: 0 auto;
    background: #fff;
    padding: 20px 0px 20px 0px;
}

.container-detail_new h2 {
    text-align: center;
    font-size: 50px;
    font-family: 'Amble-bold';
    color: #32711E;
    width: 892px;
    margin: 30px auto;
}

.container-detail_new .slick-slider {
    padding: 0px 0px 40px 0px;
}

.container-detail_new .slick-dots {
    width: 100% !important;
    bottom: 0px !important;
}

.container-detail_new .slick-dots li button:before,.container-detail_new .slick-dots li.slick-active button:before {
    background: #fff;
    border: 2px solid #005286;
    border-radius: 50%;
}

.container-detail_new .slick-dots li.slick-active button:before {
    background: #005286 !important;
}

.info-detail {
    max-width: 998px;
    margin: 0 auto;
    padding: 40px;
}

.info-detail span {
    font-family: 'Amble-light';
    font-size: 14px;
    margin-top: 10px;
    color: #4d4d4d;
}

.info-detail .text {
    font-family: 'Amble-light';
    font-size: 14px;
    margin-top: 10px;
    text-align: justify;
    color: #4d4d4d;
    line-height: 20px;
    padding: 20px 50px 0px 50px;
}

.info-detail .text div {
    margin-bottom: 10px;
}

.info-detail .text a  {
    color: #009FE3;
}

.btn-access a {
    border: 1px solid #fff;
    border-radius: 31px;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    font-family: 'Amble-light';
    padding: 10px 20px;
}

.newpaper-gallery {
    border-radius: 0px 0px 30px 30px;
    background: #fff;
}

.newpaper-header-gallery {
    position: relative;
    width: 100%;
    height: 420px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.newpaper-header-gallery::after {
    width: 100%;
    height: 100%;
    background: rgba(0, 82, 134, 0.2);
    content: '';
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: absolute;
    top: 0;
}

.newpaper-header-gallery img {
    width: 100%;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    height: 100%;
    object-fit: cover;
}

.newpaper-body {
    padding: 20px 20px 30px 20px;
}

.newpaper-body span {
    font-family: 'Amble-light';
    color: #005286;
    font-size: 12px;
}

.newpaper-body p {
    font-family: 'Amble-bold';
    color: #005286;
    margin-bottom: 20px;
    font-size: 22px;
}

.newpaper-body > a > a {
    font-family: 'Amble-bold';
    border: 1px solid #005286;
    background: #fff;
    border-radius: 30px;
    color: #005286;
    text-decoration: none;
    padding: 10px 20px;
}

.tree-new.contact {
    position: absolute;
    right: -115px;
    z-index: 1;
}

.tree-new.contact img {
    width: 300px;
    opacity: 0.5;
    position: relative;
    top: 500px;
    transform: rotate(138.73deg);
}

.mrbox svg circle {
    fill: #005286 !important;
}

.new-slick.work.sbl img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 26px;
}

@media only screen and (min-width: 1440px) {
    .container-news {
        margin-top: 300px;
    }
}

@media only screen and (max-width: 1440px) {
    .container-known_video .player-wrapper {
        position: absolute;
        width: 80%;
        right: 10%;
        height: 80vh;
        top: 5%;
    }
    .container-grid.sbl .player-wrapper {
        position: absolute;
        width: 90%;
        right: 4.4%;
        height: 83vh;
        top: 6%;
    }    
    .new-slick .slick-dots {
        left: 194px !important;
    }
    .container-detail_new .slick-dots {
        width: 70% !important;
        margin: 0 auto !important;
        bottom: 0px !important;
    }
    .info-certifications {
        grid-column: 1/5;
        display: flex;
        justify-content: center;
    }
    .tree-new.contact {
        right: -115px;
    }
    .container-detail_new .slick-dots {
        width: 70% !important;
        right: 165px !important;
    }
    .tree-new.contact img {
        width: 240px;
        opacity: 0.5;
        position: relative;
        top: 240px;
        transform: rotate(138.73deg);
    }
    .container-news .square-slick {
        right: 15% !important;
    }
    .square-us {
        top: 180px;
        right: 40px;
    }
    .square-sl-sbl {
        left: 100px;
    }
    .new-slick.work .slick-dots {
        bottom: 205px !important;
        left: -420px !important;
    }
    .card-work {
        margin-left: 280px;
    }
    .info-pm_work {
        margin-left: 100px !important;
    }
    .elipse-work {
        bottom: -570px;
    }    
    .post-img-card-rigth.etic {
        left: 0 !important;
    }
    .square-img-card.right-up {
        right: -70px;
    }
    .title-values {
        grid-column: 1/4;
        margin-left: 100px;
        color: #009FE3;
        width: 301px;
    }
    .tree-new.contact-info img {
        width: 240px;
        opacity: 0.5;
        top: 0px !important;
        position: relative;
        transform: rotate(148.73deg);
    }
}

.new-slick.work.sbl.community .slick-dots {
    left: -555px !important;
}

@media only screen and (max-width: 1440px) {
    .new-slick.work.sbl.community .slick-dots {
        left: -420px !important;
    }
    .new-slick.work.activity {
        grid-column: 2/8 !important;
    }
}

@media only screen and  (max-width: 1366px) {
    .new-slick.work.sbl .slick-dots {
        left: -400px !important;
    }
    .new-slick.work.activity {
        grid-column: 2/7 !important;
    }
    .container-grid.information .square {
        left: 0px !important;
    }
}

@media only screen and (max-width: 1280px) {
    .Map_pm {
        padding-top: 0 !important;
    }
    .container-grid_img {
        margin-top: 100px;
    }
}