.complete-form {
    font-family: 'Amble-light';
    color: #4D4D4C;
    font-size: 12px;
    position: relative;
    top: 10px;
}

.content-modal {
    position: fixed;
    background: rgba(77, 77, 76, 0.35);
    z-index: 999999;
    top: 15%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.content-modal.gallery {
    top: 0%;
}
.content-modal.pdfs .modal-send {
    padding: 40px 20px 20px 20px !important;
    width: 600px;
    height: auto;
}
.content-modal.pdfs .info-content {
    width: 100%;
}
.content-modal.pdfs .info-content svg {
    margin-top: 0px;
}
.content-modal.pdfs h6 {
    margin-bottom: 40px;
    font-family: 'Amble-bold';
    font-size: 14px;
}
.modal-send {
    position: relative;
    max-width: 416px;
    z-index: 999999 !important;
    padding: 40px 90px 0px 90px;
    height: 253px;
    background: #fff;
    box-shadow: 0px 4px 20px rgba(216, 216, 216, 0.3);
    border-radius: 19px;
}

.modal-send.gallery {
    max-width: 800px;
    height: 470px;
    padding: 0;
}
.modal-send.gallery img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.modal-send .close-modal {
    position: absolute;
    right: 10px;
    top: 10px;
}

.info-content {
    text-align: center;
}

.info-content svg {
    margin-top: 20px;
    cursor: pointer;
}

.info-content h4 {
    color: #8DB72E;
    font-family: 'Amble-bold';
    margin-top: 20px;
    font-size: 14px;
    margin-bottom: 20px;
}

.info-content p {
    font-family: 'Amble-light';
    color: #3D3D3D;
    font-size: 12px;
}

.uploader {
    position: relative;
}

.uploader label {
    cursor: pointer;
    margin: 0;
    width: 100%;
    right: 0;
}

@media only screen and (max-width: 1440px) {
    .content-modal {
        top: 5%;
    }
}