.Claims {
    background: #fff;
}

.content-claims {
    max-width: 1440px;
    margin: 0 auto;
    text-align: center;
    padding: 70px 70px 0px 70px;
}

.content-claims h2 {
    color: #145781;
    text-transform: uppercase;
    font-family: 'Amble-bold';
    font-size: 35px;
}

.content-claims p.subtitle-claims {
    font-family: 'Amble';
    margin: 0 auto;
    margin-top: 80px;
    max-width: 80%;
    font-size: 16px;
}

.divider {
    width: 20%;
    margin: 0 auto;
    margin-top: 50px;
}

.divider span {
    border-color: #ff8e32 !important;
    height: 1px;
    border-top: 1px solid #ebebeb;
    display: block;
    position: relative;
    top: 1px;
    width: 100%;
}

.services-claims {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    padding: 60px;
}

.services-claims p {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Amble';
    font-size: 25px;
}

.services-claims span {
    color: #808291;
    font-family: 'Amble-light';
}

.claims-form {
    background: #E8F0F8;
}

.claims-content__form {
    display: grid;
    max-width: 1280px;
    margin: 0 auto;
    grid-gap: 50px;
    align-items: center;
    padding: 80px 0px;
    grid-template-columns: repeat(2,1fr);
}

.img-content__form {
    max-width: 600px;
}

.img-content__form img {
    max-width: 100%;
}

.form-content {
    font-size: 30px;
    font-family: 'Amble';
}

.box{
    margin-top: 10px;
	padding: 0px;
	box-sizing: border-box;
	border-radius: 10px;
}

.box p {
    font-size: 18px;
    margin-top: 20px;
}

.box form {
    display: grid;
    grid-gap: 6px;
    grid-template-columns: repeat(2,1fr);
}

.box .inputBox{
	position: relative;
}

.box .inputBox label {
    display: block;
    color: #808291;
    font-size: 13px;
    font-family: 'Amble';
}

.box .inputBox input, select {
    width: 100%;
    display: flex;
    height: 35px;
    padding-left: 1em;
    padding-right: 2em;
    margin-bottom: 20px;
    margin-top: 4px;
    position: relative;
    border: 1px solid #d5d6de;
    border-radius: 0;
    background: #fff;
    align-items: center;
    color: #222;
    outline: none;
    transition: all 0.3s;
}

.box textarea {
    width: 100%;
    display: flex;
    height: 100px;
    padding-top: 4px;
    padding-left: 1em;
    padding-right: 2em;
    margin-bottom: 20px;
    margin-top: 4px;
    position: relative;
    border: 1px solid #d5d6de;
    border-radius: 0;
    background: #fff;
    align-items: center;
    color: #222;
    outline: none;
    transition: all 0.3s;
    font-family: 'Amble';
}

.inputBox.large {
    grid-column: 1/3 !important;
}

.box .buttonSection{
	display: flex;
	align-items: center;
}

.box button{
    background: #27ae60 none repeat scroll 0 0;
    border: 0 none;
    border-radius: 1px;
    color: white;
    cursor: pointer;
    margin: 10px 0px;
    padding: 10px 5px;
    min-width: 100px;
    position: relative;
    font-family: 'Amble';
}

.previousButton {
    margin-right: 10px !important;
}

.box:nth-of-type(2), .box:nth-of-type(3){
	display: none;
}

.messageSuccess {
    display: block !important;
    font-size: 16px;
    text-align: center;
    background: #27ae60;
    padding: 10px;
    color: #fff;
    border-radius: 2px;
}

.messageError {
    display: block !important;
    font-size: 14px;
    text-align: center;
    background: #E06C75;
    padding: 10px;
    color: #fff;
    border-radius: 2px;
    margin-bottom: 10px;
}

.conditions {
    font-family: 'Amble';
    color: #808291;
    font-size: 13px;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.conditions p {
    margin-left: 10px;
}

.conditions input {
    margin-left: 10px;
}

@media screen and (max-width: 768px) {
    .content-claims {
        padding: 50px 0px 10px 0px;
    }
    .services-claims ,.claims-content__form{
        grid-template-columns: repeat(1,1fr);
    }
    .services-claims > div {
        margin-bottom: 30px;
    }
    .form-content {
        padding: 10px;
    }
}