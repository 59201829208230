@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700&display=swap');

.Footer-pm {
    position: relative;
    margin: 0 auto;
    background: #ECECEC;
}

.accordion-footer {
    width: 100% !important;
    grid-column: 1/10;
}

.collapse-footer .content {
    max-height: 0em;
    transition: 0.3s linear 0s;
    overflow: hidden;
  }

  .collapse-footer:target .content {
    max-height: 15em;
    margin-bottom: 0px;
  }

.sp-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.sp-title svg {
    margin-right: 20px;
}

.container-footer {
    display: grid;
    grid-template-columns: repeat(9,1fr);
    align-items: flex-start;
    padding: 50px 100px 20px 100px;
    margin-bottom: 40px;
}

.Footer-pm.movil {
    display: none;
}

.logo {
    display: flex;
    grid-column: 1/3;
}

.claims {
    display: flex;
    grid-column: 3/4;
}

.declaration {
    display: flex;
    justify-content: flex-end;
    grid-column: 4/6;
}

.container-footer .information {
    display: flex;
    justify-content: flex-end;
    grid-column: 6/8;
}

.accordion-panel{
    padding: 1em;
    background:#444;
    color:#f4f4f4;
    display: none;
  }

.contact {
    display: flex;
    justify-content: flex-end;
    grid-column: 8/10;
}

.declaration .title {
    width: 168px;
    position: relative;
}

.information .title {
    width: 180px;
}

.contact .title a ,.declaration .title a , .information .title a {
    font-family: 'Raleway';
    font-size: 12px;
    color: #3d3d3d;
    text-decoration: none;
    font-weight: 700;
}

.contact .info-contact ,.declaration .info-contact a, .information .info-contact a{
    font-family: 'Raleway';
    font-weight: 300;
    font-size: 12px;
    color: #767676;
}

.contact .info-contact div ,.declaration .info-contact div, .information .info-contact div{
    margin-top: 23px;
}

.Footer-pm hr {
    width: 90%;
    margin: 0 auto;
    color: none;
    border: 1px solid rgba(118, 118, 118, 0.15);
}

.copy-socials {
    padding: 30px 100px 20px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.copy-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.copy-right div.span {
    font-family: 'Amble-light';
    font-size: 14px;
    color: #767676;
}

.copy p {
    color: rgba(0, 0, 0, 0.8);
    font-family: 'Amble';
    font-size: 14px;
}

.socials a {
    margin-left: 10px;
    cursor: pointer;
}

.Footer-pm.movil .claims {
    margin: 0 auto;
    margin-bottom: 30px;
    text-align: center;
}