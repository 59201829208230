@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

.Clients-pm {
    position: relative;
    z-index: 999998;
    background: #fff;
    height: auto;
    margin: 0 auto;
    padding-bottom: 80px;
}

.loading-products p {
    font-family: 'Amble-bold';
    color: #4D4D4C;
}

.slick-slide .img-client_slick img {
    width: 100px !important;
}

.bg-gray {
    position: relative;
    z-index: 999998;
    background: #F9F9F9;
    height: auto;
    margin: 0 auto;
    padding-bottom: 80px;
}

.container-clients {
    display: grid !important;
    padding: 100px 0px 0px 0px;
    grid-template-columns: repeat(7, 1fr);
}

.title-client {
    grid-column: 1/3;
    display: flex;
    margin-left: 30px;
}

.clients-page {
    max-width: 1440px;
    margin: auto;
    position: relative;
}

.info-clients {
    grid-column: 1/5;
    display: flex;
}

.info-clients h2 {
    width: 301px;
    color: #32711E;
    font-family: 'Amble-bold';
    font-size: 50px;
    margin-bottom: 30px;
}

.info-clients span {
    font-family: 'Amble-bold';
    font-size: 30px;
    color : #8DB72E;
}

.new-slick.work.tablet .slick-slide img {
    max-width: 100%;
}

.info-clients p,.info-client-footer p {
    font-family: 'Amble-light';
    font-size: 14px;
    color : #4D4D4C;
    margin-top: 30px;
    width: 301px;
}

.info-client-footer {
    max-width: 1200px;
}

.client-info {
    grid-column: 5/8;
}

.container-grid.clients-page div#industrial, .container-grid.clients-page div#foodservices {
    padding-top: 80px;
}

.container-grid.clients-page div#exportation {
    padding-top: 40px;
}

.container-grid.clients-page div#agroindustrial {
    padding-top: 40px;
}

.hide {
    display: none;
}

.info-client-footer {
    position: absolute;
    left: 0;
    bottom: -30px;
}

a.active svg circle {
    fill: #D9F0FF;
}

.info-client-footer h6 {
    margin-top: 20px;
    font-family: 'Amble-bold';
    font-size: 14px;
}

.info-client-footer p {
    margin-top: 12px !important;
}

.title-client h4 {
    width: 270px;
    font-family: Amble;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    font-family: 'Amble-bold';
    color: #005286;
}

.title-client p {
    margin-top: 20px;
    font-family: 'Amble-light';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #4D4D4D;
}

.img-clients_pm  {
    grid-column: 3/8;
}

.container-clients {
    max-width: 1440px !important;
    margin: 0 auto;
}

.prs-clients {
    position: relative;
    grid-column: 5/7;
    display: flex;
}

.footer-client_main h6 {
    margin-top: 40px;
    color: #4D4D4C;
    font-family: 'Amble-light';
    font-size: 14px;
    margin-bottom: 10px;
}

.footer-client_main span {
    position: relative;
    color: #4D4D4C;
    font-family: 'Amble-bold';
    font-size: 10px;
}

.types-clients {
    margin-top: 20px;
}

.types-clients svg {
    position: relative;
    z-index: 1;
    margin-right: 15px;
}

.client-slick .slick-track .img-clients {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    z-index: 1;
}

.client-slick .slick-track .img-clients .img-client_slick{
    border-radius: 50%;
    display: flex;
    width: 172px;
    margin: 10px;
    justify-content: center;
    align-items: center;
    background: #fff;
    height: 162px;
    margin-bottom: 20px;
    filter: drop-shadow(0px 2.86917px 14.3458px rgba(216, 216, 216, 0.2));
}

.client-slick .slick-next {
    right: 60px !important;
    z-index: 10000 !important;
}

.client-slick .slick-prev {
    left: 25px !important;
    z-index: 10000 !important;
}

.client-slick .slick-next::before, .client-slick .slick-prev::before {
    color: #fff !important;
    padding: 0px 20px;
    background: rgba(0, 159, 227, 0.6);
    border-radius: 50%;
    font-size: 40px;
    opacity: 1 !important;
    line-height: 100px;
    font-family: 'Poppins';
}

.client-slick .slick-prev::before {
    content: '<';
}

.client-slick .slick-next::before {
    content: '>';
}

.client-slick  {
    color: rgba(0, 159, 227, 0.6) !important;
    font-size: 50px;
    opacity: 1 !important;
}

.container-form_contact {
    display: grid;
    margin: 0 auto;
    background: #fff;
    align-items: center;
    grid-template-columns: repeat(9,1fr);
    padding-top: 50px;
}

.form-contact {
    grid-column: 2/5;
}

.form-contact h2 {
    color: #32711E;
    font-family: 'Amble-bold';
    font-size: 50px;
}

.input {
    width: 100%;
    display: block;
    margin-top: 25px;
}

.input input, .input textarea {
    padding: 12px 25px;
    border-radius: 30px;
    width: 100%;
    border: 1px solid #C4C4C4;
    font-family: 'Amble';
    outline: none;
    color: #4d4d4d;
}

.wizard-fieldset .input input,.wizard-fieldset .input textarea {
    padding: 15px 25px;
    border-radius: 30px;
    width: 100%;
    border: 1px solid #C4C4C4;
    font-family: 'Amble';
    outline: none;
    color: #4d4d4d;
}

.img-video_known .img img {
    object-fit: cover;
}

.img-company-abe {
    top: -10px;
}

.input label {
    padding: 14px 25px;
    border-radius: 30px;
    width: 100%;
    border: 1px solid #005286;
    font-family: 'Amble';
    outline: none;
    color: #005286;
    position: absolute;
    right: 0;
    font-size: 14px;
    top: 0px;
}

.input-ds {
    margin-bottom: 15px;
}

.input-ds.rt .error-ds , .input-ds .error-ds {
    position: relative;
    top: 12px;
}

.upload {
    display: none;
}

.box-container {
    overflow-x: hidden;
}

.uploader {
    padding: 10px 20px;
    border-radius: 30px;
    width: 100%;
    border: 1px solid #C4C4C4;
    font-family: 'Amble';
    outline: none;
    min-height: 53px;
    font-size: 14px;
    color: #A0A0A0;
    display: flex;
}

.uploader .input-value{
    width: 100%;
    padding: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
}

textarea {
    border-radius: 20px;
    resize: none;
}

.input input::placeholder, .input textarea::placeholder {
    font-family: 'Amble-light';
}

.conditions {
    font-family: 'Amble-light';
    color: rgba(77, 77, 76, 0.6);
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.conditions p {
    margin-left: 10px;
}

.conditions input {
    margin-left: 10px;
}

.btn-send {
    margin-top: 30px;
    margin-bottom: 40px;
}

.btn-send button {
    font-family: 'Amble-bold';
    background: #32711E;
    padding: 10px 40px;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 30px;
}

.footer-contact {
    position: relative;
    background: #D6E3D2;
    padding: 40px;
    overflow: hidden;
}

.marker-contact {
    grid-column: 2/5;
    display: flex;
    justify-content: center;
}

.call-contact {
    grid-column: 5/7;
    display: flex;
    justify-content: center;
}

.flx {
    display: flex;
    align-items: center;
    font-family: 'Amble-bold';
    font-size: 14px;
}

.flx p {
    color: #32711E;
    margin-left: 20px;
}

.flx span {
    color: rgba(77, 77, 76, 0.6);
    margin-left: 20px;
    font-family: 'Amble-light';
}

.new-tree {
    position: absolute;
    top: -100px;
    left: 0;
}

.new-tree img {
    width: 200px;
}

.ubications {
    height: 600px;
    overflow-x: scroll;
}

@media only screen and (max-width: 1440px) {
    .info-clients {
        grid-column: 2/5;
    }
    .info-client-footer {
        margin-left: 205px;
        width: 1000px;
        bottom: -50px;
    }
    .title-client {
        grid-column: 2/3;
        margin-left: 0;
    }
    .container-clients {
        width: 100%;
    }
    .img-clients_pm {
        grid-column: 3/7;
    }
    
}

@media only screen and (max-width: 1370px) {
    .info-clients {
        grid-column: 2/5;
    }
    .info-client-footer {
        margin-left: 198px;
        width: 1000px;
        bottom: -50px;
    }
}