.Quotes-pm {
    max-width: 1440px;
    background: #fff;
    height: auto;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(11, 1fr);
}

.Sidebar-Material_Quote {
    grid-column: 1/3;
    background: #EEEEEE;
    padding: 20px;
}

.Materials-sidebar_pm {
    margin-top: 20px;
}

.Materials-sidebar_pm p {
    font-size: 10px;
    color: #4c4c4c;
    font-family: 'Amble';
}

.Materials-sidebar_pm span {
    font-size: 11px;
    color: #4d4d4d;
    font-family: 'Amble-light';
}

.Materials-sidebar_pm h5 {
    font-size: 12px;
    margin-top: 20px;
    color: #4c4c4c;
    font-family: 'Amble';
    margin-bottom: 10px;
}

.Materials-sidebar_pm .material {
    position: relative;
    font-size: 11px;
    margin-top: 0px;
    color: #4c4c4c;
    font-family: 'Amble-light';
    padding: 0px 5px 0px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

li a.category {
    background: #fff;
    border-radius: 30px;
    position: relative;
    color: #4D4D4C;
    padding: 15px 25px 15px 25px;
    display: flex;
    font-size: 13px;
    justify-content: center;
    align-items: center;
}

li a.active-category {
    background: #D1EBFC;
    border-radius: 30px;
    position: relative;
    color: #005286;
    font-size: 13px;
    font-family: 'Amble-bold';
    padding: 15px 25px 15px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

li a.category span, li a.active-category span {
    margin-left: 15px !important;
}

li a.category img , li a.active-category img {
    left: 10px;
    position: absolute;
}

ul li:nth-child(2) img {
    width: 23px;
}

ul li:nth-child(6) img {
    width: 14px;
}

ul li:nth-child(7) img {
    width: 16px;
}

ul li:nth-child(3) img {
    width: 20px;
}

ul li:nth-child(4) img {
    width: 25px;
}

ul li:nth-child(5) img {
    width: 25px;
}

div.checkRadioContainer > label > input {
    visibility: hidden;
}

div.checkRadioContainer {
    max-width: 10em;
}
div.checkRadioContainer > label {
    display: block;
    border: 2px solid grey;
    margin-bottom: -2px;
    cursor: pointer;
}

div.checkRadioContainer > label > span {
    display: inline-block;
    vertical-align: top;
    line-height: 2em;
}

div.checkRadioContainer > label > input + i {
    visibility: hidden;
    color: green;
    margin-left: -0.5em;
    margin-right: 0.2em;
}

div.checkRadioContainer > label > input:checked + i {
    visibility: visible;
}

.con1 {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.con1 input {
  position: absolute;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 999999;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #807f7f;
  background: #eeeeee;
  border-radius: 3px;
}

.no-quote {
    text-align: center;
}

.no-quote p {
    color: #4D4D4C;
    font-family: 'Amble-bold';
    margin-top: 70px;
    margin-bottom: 30px;
}

.content-modal.quote {
    top: 0;
}

/* When the radio button is checked, add a blue background */
.con1 input:checked ~ .checkmark {
    background-image: url('../images/img/checkproduct.webp');
    background-size: cover;
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
}