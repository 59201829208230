.Known-pm {
    position: relative;
    height: auto;
}

.container-known, .container-distributor {
    background: #F9F9F9;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(7,1fr);
    align-items: center;
    padding-bottom: 100px;
}

.container-known .info-known {
    grid-column: 2/4;
    display: flex;
}

.info-known h3 {
    width: 498px;
    font-family: 'Amble-bold';
    font-size: 50px;
    line-height: 50px;
    color: #009FE3;
}

.info-known p {
    width: 350px;
    margin-top: 30px;
    font-family: 'Amble-bold' !important;
    font-size: 20px;
    color: #4D4D4D;
}

.products-known {
    grid-column: 4/8;
    display: flex;
    justify-content: center;
}

.container-products_pm {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 40px;
}

.container-products_pm img {
    border-radius: 50px;
}

.container-products_pm .top {
    position: relative;
    top: -100px;
}

.bioform-pm ,.line-products_pm, .material-pm, .use-app_pm {
    position: relative;
    display: flex;
    z-index: 999999;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.img-video_known .img::after, .bioform-pm::after, .line-products_pm::after, .material-pm::after, .use-app_pm::after {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 82, 134, 0.2);
    content: '';
    border-radius: 50px;
}

.bioform-pm::after, .line-products_pm::after, .material-pm::after,.use-app_pm::after {
    height: 98%;
}

.bioform-pm p,.line-products_pm p, .material-pm p, .use-app_pm p {
    position: absolute;
    color: #fff;
    z-index: 1;
    top: 60px;
    font-size: 29px;
    font-family: 'Amble';
    text-shadow: 0px 2.91257px 2.91257px rgba(0, 0, 0, 0.06);
}

.Known-video_pm {
    position: relative;
    z-index: 1;
    padding-bottom: 50px;
}

.know-lines {
    font-family: 'Amble';
    text-align: center;
    margin-top: 20px;
    font-size: 25px;
    color: #4D4D4D;
}

.container-known_video{
    padding-top: 100px;
    background: #f9f9f9;
    max-width: 1440px;
    margin: 0 auto;
    display: grid;
    position: relative;
    z-index: 1;
    grid-template-columns: repeat(8, 1fr);
}

.container-distributor {
    background: #005286;
    padding-top: 80px;
}

.container-known_video .play-known {
    position: relative;
    left: 40px;
    grid-column: 1/4;
    display: flex;
    justify-content: center;
}

.container-grid .info-pm {
    position: relative;
    grid-column: 1/5;
    display: flex;
    justify-content: center;
}

.info-pm h6 {
    width: 543px;
    font-family: 'Amble-bold';
    font-size: 50px;
    color: #32711E;
}

.info-pm p {
    width: 432px;
    font-family: 'Amble-light';
    font-size: 14px;
    color: #4c4c4c;
    margin-top: 20px;
}

.container-grid .play-known {
    position: relative;
    left: 40px;
    grid-column: 5/7;
    display: flex;
    justify-content: flex-start;
}

.container-known_video .play-known .square, .square {
    position: absolute;
    width: 430px;
    left: 0;
    height: 471px;
    background: rgba(50, 113, 30, 0.2);
    border-radius: 50px;
    transform: rotate(-9.08deg);
}

.container-grid .play-known .square, .square , .square-carvajal {
    position: absolute;
    width: 430px;
    left: -70px;
    height: 471px;
    background: rgba(50, 113, 30, 0.2);
    border-radius: 50px;
    transform: rotate(-9.08deg);
}

.square {
    background: #D1EBFC;
    left: -60px;
}

.square-carvajal {
    background: rgba(255, 255, 255, 0.14);
}

.img-video_known .img {
    width: 430px;
    height: 471px;
    position: relative;
    z-index: 1;
    top: -30px;
}

.img-video_known .img img {
    width: 100%;
    height: 100%;
    border-radius: 50px;
}

.play-button_pm {
    position: absolute;
    z-index: 10;
    right: -10px;
    bottom: 90px;
    width: 142px;
    height: 142px;
}

.container-grid .play-button_pm {
    position: absolute;
    z-index: 10;
    right: 40px;
    bottom: 90px;
    width: 142px;
    height: 142px;
}

.play-button_pm.activity {
    left: 400px;
    bottom: 200px;
}

.play-known_carbajal .play-button_pm {
    position: absolute;
    z-index: 10;
    right: -130px;
    bottom: 90px;
    width: 142px;
    height: 142px;
}

.new-slick.work.activity .slick-slide > div > div {
    display: flex !important;
    align-items: flex-end;
    justify-content: space-between;
}

.info-post-activity {
    position: relative;
    top: -400px;
    left:-100px;
    width: 400px;
}

.info-post-activity p {
    z-index: 999999;
    color: #fff;
    font-size: 50px;
    font-family: 'Amble-bold';
    margin-bottom: 14px;
}

.info-post-activity span {
    color: #fff;
    font-size: 14px;
    font-family: 'Amble-light';
}

.play-button_pm button {
    background: #8DB72E;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: none;
    cursor: pointer;
    outline: none;
}
.play-button_pm button.blue {
    background: #009FE3;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: none;
    cursor: pointer;
    outline: none;
}

.info-known_our {
    position: relative;
    top: -40px;
    left: 40px;
    grid-column: 5/8;
    display: flex;
    justify-content: center;
    align-items: center;
}

.info-known_our h4 {
    width: 497px;
    color: #8DB72E;
    font-size: 50px;
    font-family: 'Amble-bold';
}

.info-known_our p {
    width: 254px;
    margin-top: 30px;
    font-family: 'Amble-light';
    font-size: 14px;
    color: #4D4D4D;
}

.info-distributor_hm {
    grid-column: 2/4;
    display: flex;
}

.info-distributor_hm div{
    position: relative;
    width: 400px;
}

.info-distributor_hm h3 {
    width: 400px;
    font-family: 'Amble-bold';
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 104.2%;
    color: #FFFFFF;
}

.info-distributor_hm p {
    font-family: 'Amble-light';
    font-style: normal;
    width: 345px;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    margin-top: 35px;
    color: #FFFFFF;
}

.info-distributor_hm button {
    font-family: 'Amble-bold';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    background: #009FE3;
    border-radius: 30px;
    color: #FFFFFF;
    border: none;
    padding: 12px 30px;
    margin-top: 35px;
    cursor: pointer;
    outline: none;
}

.img-distributor_hm {
    grid-column: 4/8;
    display: flex;
    justify-content: center;
    position: relative;
}

.square-distributor {
    background: #33759E;
    border-radius: 50px;
    transform: rotate(-9.08deg);
    width: 411.23px;
    height: 443.65px;
    top: 20px;
    right: 60px;
    position: absolute;
}

.img-distributor {
    position: relative;
    width: 411.23px;
    height: 443.65px;
}

.img-distributor::after {
    background: rgba(0, 82, 134, 0.2);
    border-radius: 50px;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
}

.img-distributor_hm img {
    border-radius: 50px;
    position: relative;
}

/* Nosotros */

.About-us_pm {
    background: #f9f9f9;
    margin: 0 auto;
}

.container-about_us {
    padding-top: 100px;
    display: grid;
    grid-template-columns: repeat(7,1fr);
}

.info-about {
    grid-column: 2/5;
    margin-top: 0px;
}

.info-about h3 {
    width: 328px;
    font-family: Amble;
    font-style: normal;
    margin-top: 30px;
    font-family: 'Amble-bold';
    font-size: 30px;
    line-height: 104.2%;
    color: #009FE3;
}

.info-about p {
    font-family: 'Amble-light';
    font-style: normal;
    width: 428px;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #4D4D4C;
    margin-top: 30px;
}

.card-about {
    position: relative;
    grid-column: 5/8;
    display: flex;
    justify-content: flex-start;
}

.container-square_us {
    position: relative;
    width: 634.39px;
    height: 471.87px;
}

.player-wrapper {
    position: absolute !important;
    width: 580px;
    height: 560px;
    z-index: 999999 !important;
    bottom: 250px;
}

.html5-video-player:not(.ytp-transparent), .html5-video-player.unstarted-mode, .html5-video-player.ad-showing, .html5-video-player.ended-mode, .html5-video-player.ytp-fullscreen {
    border-radius: 5px !important;
}

body.date-20201112 {
    border-radius: 5px !important;
}

.square.us {
    position: absolute;
    width: 564px;
    height: 420px;
    left: -90px;
    top: -50px;
    background: rgba(50, 113, 30, 0.2);
    border-radius: 50px;
    transform: rotate(-5.34deg);
}

.card-about .img-about_us{
    width: 530px;
    height: 420px;
    position: relative;
    z-index: 1;
    top: -45px;
    left: -55px;
}

.img-about_us .img img {
    width: 100%;
    height: 100%;
    border-radius: 50px;
}

.img-about_us .img::after {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 82, 134, 0.2);
    content: '';
    border-radius: 50px;
}

.button-cat button, .button-cat a {
    display: block;
    width: 100%;
    margin-top: 20px;
    color: #32711E;
    border: 1px solid #32711E;
    border-radius: 30px;
    background: transparent;
    text-decoration: none;
    font-size: 13px;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    font-family: 'Amble-bold';
    outline: none;
}

@media screen and (max-height: 750px) and (max-width: 1440px) {
    .Known-pm {
        margin-top: 200px;
    }
    .Known-pm .Known-pm {
        margin-top: 0px !important;
    }
    .container-news {
        margin-top: 200px;
    }
}

@media screen and (max-height: 1366px) and (max-width: 1024px) {
    .Rotate-slick::before {
        left: 92px;
    }
    .content-slick-new.new {
        height: 55vh !important;
    }
    .container-gallery ,.container-view_journalists{
        max-width: 980px;
    }
    .form-contact {
        grid-column: 2/9 !important;
        max-width: 980px;
    }
    .img-video_known .img img,.container-products_pm img, .img-video_known .img::after, .bioform-pm::after, .line-products_pm::after, .material-pm::after, .use-app_pm::after {
        border-radius: 20px !important;
    }
    .container-form_contact {
        padding-top: 0px !important;
    }
    .container-form_contact .prs-clients {
        display: none;
    }
    .tree-new.contact img {
        top: 480px !important;
    }
    .tree-new.contact-info img {
        width: 200px;
        top: 100px !important;
        left: -30px;
    }
    .footer-contact {
        padding: 40px 0px !important;
    }
    .container-grid .market-contact , .container-grid .call-contact {
        justify-content: flex-start !important;
    }
    .container-grid .call-contact {
        grid-column: 5/8;
    }
    .Distributor-pm {
        height: 20vh !important;
    }
    .ubications {
        grid-column: 1/8 !important;
        margin-top: 60px;
        margin-left: 160px !important;
    }
    .container-detail_product {
        padding-top: 40px !important;
    }
    .container-detail_product .img-detail_product {
        grid-column: 1/5 !important;
        padding-top: 40px !important;
        justify-content: center !important;
    }
    .container-detail_product .detail-info {
        grid-column: 1/5 !important;
        padding-top: 100px;
        margin-left: 85px;
    }
    .detail-info .title-detail_product h4 ,.list-detailt_product {
        max-width: 90% !important;
    }
    .products-default {
        grid-gap: 0px !important;
    }
    .form-wizard .wizard-fieldset.show {
        max-width: 868px !important;
    }
    .container-dates , .container-quotes-products{
        grid-column: 1/3 !important;
    }
    .form-wizard .form-wizard-steps li {
        width: 45% !important;
    }
}

@media screen and (max-height: 660px) and (max-width: 1370px) {
    .Known-pm {
        margin-top: 0px;
    }
    .Known-pm .Known-pm {
        margin-top: 0px !important;
    }
    .Slicks-pm.Us .slick-initialized .slick-slide {
        height: 100vh !important;
    }
}

@media screen and (min-height: 1360px) and (max-width: 1024px) {
    .Known-pm {
        padding-top: 100px;
    }
    .Known-pm .Known-pm {
        padding-top: 0px;
    }
    .Rotate-slick::before {
        height: 130%;
    }
    .News-pm .tree-new {
        right: 0 !important;
    }
    .News-pm .tree-new.news img {
        right: 0 !important;
    }
}


@media only screen and (max-width: 1440px) {
    .container-grid .play-button_pm {
        right: -100px;
    }
    .img-distributor_hm {
        grid-column: 5/8;
    }
    .post-pet-sbl.activity {
        right: 0 !important;
    }
    .info-post-activity {
        z-index: 99999;
    }
    .new-slick.work.activity .slick-dots {
        left: 580px !important;
    }
    .player-wrapper {
        position: absolute !important;
        height: 500px;
        min-width: 14.3%;
        bottom: 90px;
        z-index: 999999 !important;
    }
}