.Slicks-pm {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    height: auto;
    background: #f9f9f9;
}

.Slicks-pm.Us {
    background: #fff;
}

.slick-slider {
    width: 100%;
    height: 100%;
}

.slick-list {
    z-index: 2;
    padding: 0 !important;
}

.Radius {
    position: absolute;
    width: 2200px;
    height: 1206.42px;
    left: -158px;
    top: -360.45px;
    /* background: rgba(119, 121, 114, 0.35); */
    background: rgba(152, 194, 29, 0.35);
    border-radius: 191px;
    transform: rotate(-16.85deg);
}

.Radius.Us {
    position: absolute;
    width: 2200px;
    height: 1206.42px;
    left: -158px;
    top: -360.45px;
    z-index: 2;
    background: #CBDCE7 !important;
    border-radius: 140px !important;
    transform: rotate(-16.85deg);
}

.Radius.Us.Clients, .Radius.Us.News {
    background: #D6E3D2 !important;
}

.Rotate-slick.Us::before {
    background-color: rgba(0, 82, 134, 0.2);
}

.Rotate-slick::before {
    position: absolute;
    z-index: 1023;
    content: '';
    background-color: rgba(0, 82, 134, 0.2);
    left: 45px;
    top: 240px;
    transform: rotate(21.34deg);
    width: 100%;
    height: 110%;
}

.Rotate-slick {
    overflow: hidden;
    position: relative;
    width: 2200px;
    height: 1206.42px;
    top: -410px;
    z-index: 3000;
    left: -153px;
    border-radius: 191px;
    transform: rotate(-21.34deg);
}

.Rotate-slick::after {
    content: '';
    z-index: 1022;
    position: absolute;
    background-blend-mode: soft-light;
    /* background-color: rgba(144, 145, 135, 0.3); */
    background-repeat: no-repeat;
    background-size: cover;
    left: 28px;
    top: 346px;
    background-image: url("../images/img/bannerexpress.webp");
    transform: rotate(21.34deg);
    min-width: 100%;
    height: 101%;
}
.Rotate-slick.first::before {
    background-color: transparent !important;
}
.Rotate-slick.first::after {
    content: '';
    z-index: 1022;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    left: -28px;
    top: 426px;
    background-image: url("../images/img/bannerbioform-v2.jpg");
    transform: rotate(21.34deg);
    min-width: 100%;
    height: 101%;
}

.Rotate-slick.first p {
    margin: 10px 0px 10px 0;
}

.Rotate-slick.second::after {
    content: '';
    z-index: 1022;
    position: absolute;
    background-blend-mode: soft-light;
    background-color: rgba(8, 117, 167, 0.3);
    background-repeat: no-repeat;
    background-size: cover;
    left: 28px;
    top: 346px;
    background-image: url("../images/img/bannerhome.webp");
    transform: rotate(21.34deg);
    min-width: 100%;
    height: 101%;
}

.Rotate-slick.Us::after {
    content: '';
    z-index: 1022;
    position: absolute;
    background-blend-mode: soft-light;
    background-color: rgba(0, 82, 134, 0.2);
    background-repeat: no-repeat;
    background-size: cover;
    left: -26px;
    top: 346px;
    transform: rotate(21.34deg);
    min-width: 100%;
    height: 100%;
}

.Rotate-slick.Us::after {
    background-image: url('../images/img/bannerus.webp');
}

.Rotate-slick.Us.Sb::after {
    background-image: url('../images/img/bannersostenibility.webp');
}

.Rotate-slick.Us.Wu::after {
    background-image: url('../images/img/workwithus.webp') !important;
}

.Rotate-slick.Us.Cl::after {
    background-image: url('../images/img/clients.webp') !important;
}

.Rotate-slick.Us.New::after {
    background-image: url('../images/img/bannernotice.webp') !important;
}

.Rotate-slick.Us.Ctt::after {
    background-image: url('../images/img/contacto.webp') !important;
}

.Radius-us{
    position: absolute;
    width: 2200px;
    height: 1206.42px;
    left: -58px;
    top: -49.45px;
    background: rgba(0, 82, 134, 0.16);
    border-radius: 191px;
    transform: rotate(-16.85deg);
}

.Slicks-us_pm {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    height: auto;
    background: #f9f9f9;
}

.Slicks-sb_pm , .Slicks-wwu_pm, .Slicks-news_pm, .Slicks-cnt_pm {
    position: relative;
    max-width: 1440px;
    overflow: hidden;
    margin: 0 auto;
    height: 75vh;
    background: #FFFFFF;
}

.Slicks-clts_pm {
    position: relative;
    max-width: 1440px;
    overflow: hidden;
    margin: 0 auto;
    height: 75vh;
    background: #FFFFFF;
}

.Radius-sb, .Radius-wwu, .Radius-cnt {
    position: absolute;
    width: 1767.76px;
    height: 55vh;
    left: -58px;
    top: -49.45px;
    background: rgba(0, 82, 134, 0.16);
    border-radius: 191px;
    transform: rotate(-16.85deg);
}

.Radius-clts, .Radius-news {
    position: absolute;
    width: 1767.76px;
    height: 55vh;
    left: -58px;
    top: -49.45px;
    background: rgba(50, 113, 30, 0.2);
    border-radius: 191px;
    transform: rotate(-16.85deg);
}


.Rotate-slick_us {
    overflow: hidden;
    position: relative;
    width: 2700px;
    height: 94vh;
    top: -600px;
    left: -102px;
    border-radius: 191px;
    transform: rotate(-21.34deg);
}

.Rotate-slick_sb, .Rotate-slick_wwu, .Rotate-slick_clts, .Rotate-slick_news, .Rotate-slick_cnt {
    overflow: hidden;
    position: relative;
    width: 2700px;
    height: 94vh;
    top: -600px;
    left: -102px;
    border-radius: 191px;
    transform: rotate(-21.34deg);
}

.Rotate-slick_us::before ,
.Rotate-slick_sb::before , 
.Rotate-slick_wwu::before, 
.Rotate-slick_clts::before, 
.Rotate-slick_news::before,
.Rotate-slick_cnt::before  {
    position: absolute;
    z-index: 1023;
    content: '';
    width: 1781.73px;
    height: 1315.57px;
    left: -318px;
    background-color: rgba(0, 82, 134, 0.3);
    border-radius: 191px;
    transform: rotate(-21.34deg);
}

.text-map_pm.tablet {
    display: none;
}

.Rotate-slick_us::after {
    content: '';
    z-index: 1022;
    position: absolute;
    background-blend-mode: soft-light;
    background-color: rgba(0, 82, 134, 0.2);
    background-repeat: no-repeat;
    background-size: contain;
    left: -55px;
    top: 350px;
    opacity: 1;
    transform: rotate(21.34deg);
    width: 1560px;
    height: 80vh;
}

.Rotate-slick_wwu::after {
    content: '';
    z-index: 1022;
    position: absolute;
    background-blend-mode: soft-light;
    background-color: rgba(0, 82, 134, 0.2);
    background-repeat: no-repeat;
    background-size: cover;
    left: -55px;
    top: 350px;
    opacity: 1;
    transform: rotate(21.34deg);
    width: 1560px;
    height: 80vh;
}


.Rotate-slick_sb::after {
    content: '';
    z-index: 1022;
    position: absolute;
    background-blend-mode: soft-light;
    background-color: rgba(0, 82, 134, 0.2);
    background-repeat: no-repeat;
    background-size: contain;
    left: -55px;
    top: 350px;
    opacity: 1;
    transform: rotate(21.34deg);
    width: 1560px;
    height: 80vh;
}

.Rotate-slick_clts::after {
    content: '';
    z-index: 1022;
    position: absolute;
    background-blend-mode: soft-light;
    background-color: rgba(0, 82, 134, 0.2);
    background-repeat: no-repeat;
    background-size: contain;
    left: -55px;
    top: 350px;
    opacity: 1;
    transform: rotate(21.34deg);
    width: 1560px;
    height: 80vh;
}

.Rotate-slick_news::after {
    content: '';
    z-index: 1022;
    position: absolute;
    background-blend-mode: soft-light;
    background-color: rgba(20, 20, 20, 0.2);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    left: -55px;
    top: 350px;
    opacity: 1;
    transform: rotate(21.34deg);
    width: 1560px;
    height: 80vh;
}

.Rotate-slick_cnt::after {
    content: '';
    z-index: 1022;
    position: absolute;
    background-blend-mode: soft-light;
    background-color: rgba(0, 82, 134, 0.2);
    background-repeat: no-repeat;
    background-size: contain;
    left: -55px;
    top: 350px;
    opacity: 1;
    transform: rotate(21.34deg);
    width: 1560px;
    height: 80vh;
}

.Rotate-prueba {
    overflow: hidden;
    position: relative;
    width: 1920px;
    height: 100vh;
    top: -330px;
    left: -152px;
    border-radius: 191px;
    transform: rotate(-21.34deg);
}

.Elipse {
    position: absolute;
    z-index: 999999;
    top: 50px;
    left: 234px;
    transform: rotate(21.34deg);
    background-repeat: no-repeat;
}

.Elipse img {
    width: 378px;
    height: 408px;
}

.biof {
    position: absolute;
    z-index: 999999;
    top: 260px;
    left: 350px;
    transform: rotate(21.34deg);
    background-repeat: no-repeat;
}

.biof img {
    width: 133px;
    height: 110px;
}

.Slicks-pm ul.slick-dots {
    transform: rotate(90deg);
    right: 0px;
    top: 270px;
    z-index: 1000;
    width: 20%;
    height: 10%;
}

.slick-dots li button:before {
    opacity: 1 !important;
    color: transparent !important;
    padding: 1px !important;
    width: 8px !important; 
    height: 8px !important;
}


.slick-dots li.slick-active button:before {
    opacity: 1 !important;
    border-radius: 50%;
    background: #fff;
}

.slick-dots li {
    border: 1px solid #fff;
    border-radius: 50%;
    width: 12px !important; 
    height: 12px !important;
}

.info-slick {
    position: relative;
    left: 160px;
    top: 840px;
    z-index: 99999999;
    transform: rotate(21.34deg);
}

.info-slick.Us {
    position: relative;
    left: 360px;
    top: 700px;
    z-index: 999999;
    transform: rotate(21.34deg);
}

.Rotate-slick.Us.Main .info-slick.Us {
    left: 260px;
    top: 600px ;
}

.info-slick.Us p {
    width: 385px;
}

.info-slick_us,.info-slick_sb ,.info-slick_wwu, .info-slick_clts, .info-slick_news {
    position: relative;
    left: 50px;
    top: 860px;
    z-index: 999999;
    transform: rotate(21.34deg);
}

.info-slick h2,.info-slick_us h2, .info-slick_sb h2, .info-slick_wwu h2, .info-slick_clts h2, .info-slick_news h2  {
    color: #fff;
    font-size: 50px;
    width: 485px;
    font-family: 'Amble';
}

.info-slick p ,.info-slick_us p, .info-slick_sb p, .info-slick_wwu p, .info-slick_clts p , .info-slick_news p{
    color: #fff;
    font-size: 19px;
    margin-top: 20px;
    font-family: 'Amble-light';
    margin-bottom: 40px;
}

.info-slick .more-btn button{
    color: #fff;
    font-size: 15px;
    margin-top: 20px;
    background: #32711E;
    font-family: 'Amble-bold';
    border: none;
    border-radius: 30px;
    padding: 10px 45px;
    cursor: pointer;
    transition: 0.6s;
    outline: none;
}

.info-slick a {
    color: #fff;
    font-size: 15px;
    margin-top: 20px;
    text-decoration: none;
    background: #32711E;
    font-family: 'Amble-bold';
    border: none;
    border-radius: 30px;
    padding: 10px 45px;
    cursor: pointer;
    transition: 0.6s;
    outline: none;
}

.info-slick .more-btn button:hover{
    background: #409427 !important;
    transition: 0.6s;
}

.footer-slick {
    position: relative;
    top: -280px;
}

.footer-slick .tree-pm img {
    position: absolute;
    z-index: 1;
}

.content-slick-home {
    height: 125vh;
}

.content-slick-new {
    height: 128vh !important;
}

.info-map_pm.title {
    display: none
}

@media only screen and (max-height: 660px) {
    .Rotate-slick.Us {
        background-size: cover !important;
        width: 1560px !important;
        height: 130vh !important;
        top: -450px !important;
        left: -78px !important;
    }
    .Radius.Us {
        height: 66vh !important;
    }
    .Rotate-slick.Us::before {
        height: 151vh !important;
    }
    .content-slick-home {
        height: 170vh !important;
    }
    .content-slick-new {
        height: 94vh !important;
    }
}

@media only screen and (max-width: 1370px) and (max-height: 900px) {
    .content-slick-home {
        height: 145vh !important;
    }
}

@media only screen and (max-width: 1440px) and (max-height: 900px) {
    .Rotate-slick.Us {
        height: 104vh !important;
    }
    .Rotate-slick.Us.Sb::after {
        top: 515px !important;
        left: -120px !important;
    }
    .Rotate-slick.Us.Wu::after {
        top: 615px !important;
        left: -280px !important;
    }
    .Rotate-slick.Us.Cl::after {
        top: 595px !important;
        left: -100px !important;
    }
    .Rotate-slick.Us.New::after {
        top: 435px !important;
        left: -280px !important;
    }
    .Rotate-slick.Us.Ctt::after {
        top: 615px !important;
        left: -280px !important;
    }
}

@media only screen and (max-width: 1440px) and (max-height: 750px) {
    .content-slick-new {
        height: 80vh !important;
    }
    .Rotate-slick.Us {
        height: 111vh !important;
    }
    .Radius.Us {
        top: -70px !important;
    }
    .info-slick.Us {
        left: 50px !important;
    }
    .Rotate-slick.Us.Sb::after {
        top: 515px !important;
        left: -120px !important;
    }
    .Rotate-slick.Us.Wu::after {
        top: 615px !important;
        left: -280px !important;
    }
    .Rotate-slick.Us.Cl::after {
        top: 595px !important;
        left: -100px !important;
    }
    .Rotate-slick.Us.New::after {
        top: 435px !important;
        left: -280px !important;
    }
    .Rotate-slick.Us.Ctt::after {
        top: 615px !important;
        left: -280px !important;
    }
    .tree-new.contact img {
        top: 100px !important;
    }
}

@media only screen and (max-width: 1920px) and (max-height: 735px) {
    .content-slick-home.bg {
        height: 149vh !important;
    }
}
  
@media only screen and (max-width: 1440px) and (max-height: 900px) { 
    .Rotate-slick::after {
        background-size: contain !important;
    }
    .content-slick-home {
        height: 125vh;
    }
    .Rotate-slick.Us.Main .info-slick.Us {
        left: -20px !important;
        top: 880px !important;
    }
    .Rotate-slick.Us::after {
        background-size: contain;
    }
    .info-slick.Us {
        left: 250px;
        top: 700px;
    }
    .Rotate-slick::before {
        height: 110%;
    }
    .tree-new {
        top: -90px !important;
    }
    .Radius.Us {
        position: absolute;
        width: 1767.76px;
        height: 52vh;
        left: -58px;
        top: -60.45px;
        background: rgba(0, 82, 134, 0.16);
        border-radius: 191px;
        transform: rotate(-20.85deg);
    }
    .info-slick.Us {
        transform: rotate(26.34deg);
        top: 1000px !important;
    }
    .Rotate-slick.Us {
        width: 2700px;
        height: 111vh;
        top: -750px;
        left: -176px;
        border-radius: 120px;
        transform: rotate(-26.34deg);
    }
    .Rotate-slick.Us::after {
        background-color: rgba(0, 82, 134, 0.2);
        background-repeat: no-repeat;
        left: -260px;
        top: 700px;
        opacity: 1;
        transform: rotate(26.34deg);
        width: 1560px;
    }
    .Rotate-slick.Us::before {
        min-height: 142vh;
        left: -80px;
        width: 100%;
    }
    .info-slick.Us {
        top: 700px;
        left: 100px;
    }
}

@media screen and (max-width: 1440px) and (max-height: 660px) {
    .img-new_slick_us, .img-new_slick {
        left: 0px !important;
    }
    .content-slick-new {
        height: 98vh !important;
    }
    .Slicks-pm.Us .slick-initialized .slick-slide {
        height: 110vh !important;
    }
    .Rotate-slick.Us::after {
        top: 350px;
        left: -125px;
    }
    .info-slick.Us {
        top: 700px !important;
    }
    .Known-pm {
        margin-top: 0px !important;
    }
}

@media screen and (max-width: 1370px) and (max-height: 660px)  {
    .Rotate-slick::after{
        background-size: unset !important;
    }
    .content-slick-home {
        height: 120vh;
    }
    .Rotate-slick.Us::after {
        background-size: contain !important;
        max-width: 1440px !important;
        min-height: 141vh !important;
    }
    .info-slick.Us {
        left: 250px;
        top: 700px;
    }
    .Rotate-slick::before {
        height: 110%;
    }
    .new-slick .slick-dots {
        left: 200px !important;
    }
    .tree-new {
        top: -90px !important;
    }
    .Radius.Us {
        position: absolute;
        width: 1767.76px;
        height: 60vh;
        left: -58px;
        top: -60.45px;
        background: rgba(0, 82, 134, 0.16);
        border-radius: 191px;
        transform: rotate(-20.85deg);
    }
    .info-slick.Us {
        transform: rotate(26.85deg);
        top: 650px !important;
    }
    .Rotate-slick.Us {
        height: 120vh;
        top: -750px;
        left: -86px !important;
        border-radius: 191px;
        transform: rotate(-26.34deg);
    }
    .Rotate-slick.Us::after {
        background-color: rgba(0, 82, 134, 0.2);
        background-repeat: no-repeat;
        left: -260px;
        top: 300px;
        opacity: 1;
        transform: rotate(26.34deg);
        width: 1560px;
        min-height: 100vh;
    }
    .Rotate-slick.Us::before {
        min-height: 142vh;
        left: -80px;
        width: 100%;
    }
    .info-slick.Us {
        top: 700px;
        left: 100px;
    }
}

@media only screen and (max-width: 1370px) {
    .img-new_slick_us, .img-new_slick {
        left: 120px !important;
    }
    .img-new_slick {
        left: 30px !important;
    }
    .new-slick.work.sbl.community.table .slick-dots {
        left: -395px !important;
    }
    .new-slick.plant .slick-dots {
        left: 185px !important;
    }
    .stb-slick.tablet .slick-dots {
        left: 435px !important;
    }
    .new-slick.work.activity.tablet {
        grid-column: 2/8 !important;
    }
    .new-slick.work.activity.tablet .slick-dots {
        left: 710px !important;
    }
    .new-slick.work.tablet .slick-dots {
        left: -400px !important;
    }
}

@media only screen and (max-width: 1280px) {
    .Rotate-slick.Us {
        height: 110vh;
        top: -820px;
        left: -135px;
    }
    .Radius.Us {
        height: 70vh;
        left: -50px;
        top: -250px;
    }
    .Rotate-slick.Us::after {
        height: 140vh !important;
        top: 700px;
        left: -465px;
        background-size: unset !important;
    }
    .Slicks-pm.Us .slick-initialized .slick-slide {
        height: 80vh !important;
    }
    .tree-new.contact img {
        top: 350px !important;
        right: 45px;
        width: 200px !important;
    }
    .new-slick.tablet .slick-dots {
        left: 170px !important;
    }
    .img-carbajal,.square-carbajal {
        max-width: 572px;
        max-height: 340px;
    }
    .info-vision, .info-mision, .info-promise_value, .info-propose ,.square-mision, .square-vision, .square-promise_value, .square-propose {
        width: 230px !important;
    }
    .container-vision_mision_pm {
        flex-wrap: wrap;
    }
    .img-new_slick_us {
        height: 340px;
    }
    .img-new_slick_us,.container-news .square-slick , .tablet .img-new_slick,.card-about .img-about_us,.square.us{
        max-width: 500px;
        height: 340px;
    }
    .img-new_slick_us::after{
        height: 340px !important;
    }
    .img-about_us .img::after {
        height: 94% !important;
    }
    .tablet .container-news .square-slick {
        right: 20% !important;
    }
    .tablet .img-new_slick {
        left: 0px !important;
    }
    .new-slick.plant.tablet .img-new_slick {
        left: 120px !important;
    }
}

@media screen and (max-width: 1280px) and (max-height: 800px) {
    .content-slick-home {
        height: 140vh !important;
    }
    .info-slick.Us {
        left: 10px;
    }
    .Rotate-slick.Us {
        height: 110vh !important;
    }
    .Rotate-slick.Us.Main::after {
        top: 605px !important;
        left: -350px !important;
    }
    .Rotate-slick.Us.Main.tablet .info-slick {
        top: 890px !important;
        left: -100px !important;
    }
    .Rotate-slick.Us.Sb::after {
        top: 605px !important;
        left: -240px !important;
    }
    .Rotate-slick.Us.Wu::after {
        top: 615px !important;
        left: -280px !important;
    }
    .Rotate-slick.Us.Cl::after {
        top: 695px !important;
        left: -260px !important;
    }
    .Rotate-slick.Us.New::after {
        top: 635px !important;
        left: -380px !important;
    }
    .Rotate-slick.Us.Ctt::after {
        top: 615px !important;
        left: -280px !important;
    }
}


@media only screen and (max-width: 1024px) {
    .container-header-rs .Logo-pm {
        grid-column: 1/4;
        display: block;
    }
    .container-header-rs .Logo-pm a{
        margin-left: 2px;
    }
    .movile-active .container-header-rs .Ecommerce-btn button {
        position: relative;
        top: -15px;
        left: 10px;
    }
    .content-slick-home {
        height: 90vh;
    }
    .container-known .info-known ,.products-known {
        grid-column: 1/7 !important;
        display: flex;
        justify-content: center;
    }
    .products-known {
        margin-top: 60px;
    }
    .container-products_pm .top {
        top: 0px !important;
    }
    .container-products_pm {
        grid-gap: 50px !important;
        margin-left: 140px;
    }
    .container-products_pm img {
        width: 300px !important;
        height: 200px;
    }
    .material-pm a, .line-products_pm a{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .container-title-new h6 {
        grid-column: 2/7 !important;
        margin-left: 35px;
    }
    .container-products_business {
        grid-column: 1/6 !important;
        margin-left: 100px !important;
        justify-content: flex-start !important;
    }
    .container-products_business > div {
        margin-left: 75px !important;
    }
    .info-distributor_hm div ,.info-distributor_hm p{
        width: 90% !important;
        text-align: center;
        margin: 0 auto;
    }
    .info-distributor_hm ,.img-distributor_hm{
        grid-column: 2/7 !important;
        display: flex;
        justify-content: flex-start;
    }
    .img-distributor_hm {
        margin-top: 50px;
    }
    .img-distributor {
        width: 600px !important;
        height: 400px !important;
    }
    .square-distributor {
        width: 600px !important;
        height: 400px !important;
        top: 35px !important;
        right: 40px !important;
    }
    .img-distributor img ,.img-video_known .img img{
        width: 100%;
        object-fit: cover;
        height: 400px !important;
    }
    .container-known_video,.info-known_our ,.title-client,.img-clients_pm {
        grid-column: 2/7 !important;
    }
    .info-known_our {
        grid-row: 1/2;
        left: 0px !important;
    }
    .News-pm .tree-new {
        right: 100px !important;
        top: -10px !important;
    }
    .News-pm {
        overflow: hidden;
    }
    .News-pm .tree-new.news img {
        width: 150px;
        transform: rotate(48.73deg);
    }
    .News-pm .slick-slider {
        position: relative !important;
        z-index: 1000 !important;
    }
    .container-known_video .play-known {
        margin-top: 40px !important;
    }
    .container-known_video {
        padding-top: 0px !important;
    }
    .img-clients_pm {
        margin-top: 40px !important;
    }
    .container-known_video .play-known .square, .square ,.img-video_known .img {
        width: 600px !important;
        height: 400px !important;
    }
    .play-known .square {
        left: 20px !important;
    }
    .info-known_our {
        margin-top: 100px !important;
        text-align: center;
    }
    .container-known_video {
        grid-template-columns: repeat(7,1fr) !important;
    }
    .info-certifications ,.certifications-companies,.info-new_slick, .post-new_slick,.play-known {
        grid-column: 2/7 !important;
        left: 0 !important;
    }
    .info-new_slick {
        margin-top: 30px !important;
    }
    .info-new_slick h6,.info-new_slick p {
        width: 100% !important;
    }
    .container-certifications {
        margin-top: 0px !important;
    }
    .info-certifications div, .info-certifications span, .info-certifications p {
        width: 99% !important;
    }
    .certifications-companies > div {
        margin-top: 20px !important;
    }
    .companies_us {
        margin-bottom: 60px !important;
    }
    .img-company-abe {
        top: -10px;
    }
    .img-company-gptw21 {
        top: -10px;
    }
    .tree-new {
        top: -180px !important;
    }
    .new-slick.tablet .slick-dots {
        left: 0px !important;
        margin: 0px auto;
    }
    .post-new_slick {
        padding-bottom: 260px !important;
    }
    .info-distributor_hm h3 {
        width: 90% !important;
        margin: 0 auto;
    }
    .Rotate-slick.Us {
        height: 94vh;
    }
    .Radius.Us {
        height: 47vh;
        left: -45px;
        top: -350px;
        width: 2440px;
    }
    .Slicks-pm.Us .slick-initialized .slick-slide {
        height: 55vh !important;
    }
    .Rotate-slick::before {
        top: 0 !important;
    }
    .Rotate-slick.Us {
        max-width: 1400px;
    }
    .info-carbajal.about {
        grid-column: 2/7 !important;
        justify-content: flex-start;
    }
    .info-carbajal.about div {
        width: 100%;
    }
    .img-carbajal.about {
        grid-column: 2/8;
        margin-top: 10px;
    }
    .img-map_pm {
        grid-column: 2/10 !important;
    }
    .info-map_pm {
        grid-column: 2/10 !important;
        justify-content: center !important;
        display: flex !important;
        right: 0 !important;
        margin-top: 40px;
        width: 100% !important;
        margin-bottom: 40px;
    }
    .info-map_pm.title {
        grid-column: 2/7 !important;
        justify-content: flex-start !important;
        margin-bottom: 0px !important;
    }
    .info-map_pm .info-map-flex{
        display: flex;
        width: 100% !important;
        justify-content: center;
    }
    .text-map_pm.tablet {
        display: block;
        padding-bottom: 50px !important;
    }
    .map-footer-none {
        display: none;
    }
    .pm-inf_sk {
        margin-right: 0px !important;
    }
    .skills-gc {
        justify-content: space-between !important;
    }
    .pm-inf_sk svg {
        width: 90px !important;
        height: 98px !important;
    }
    .container-about_us {
        padding-top: 40px !important;
    }
    .container-about_us p {
        width: 100% !important;
    }
    .info-about {
        grid-column: 2/7 !important;
    }
    .card-about {
        margin-top: 100px;
        grid-column: 2/7 !important;
        display: flex !important;
        justify-content: center !important;
    }
    .card-about .square.us {
        left: 0px !important;
    }
    .img-map_pm.information {
        justify-content: center !important;
    }
    .footer-dc {
        right: 0 !important;
    }
    .post-new_slick_us {
        grid-column: 2/8 !important;
        margin-top: 40px !important;
        margin-bottom: 40px;
    }
    .info-footer_news_us {
        grid-column: 2/8 !important;
    }
    .tablet .container-news .square-slick {
        right: 25% !important;
    }
    .new-slick.plant.tablet .square-slick {
        right: 35% !important;
    }
    .makeStyles-paper-1 {
        max-width: 860px !important;
    }
    .products-default {
        grid-template-columns: repeat(2,1fr) !important;
    }
    .img-footer_news_us {
        grid-column: 1/8 !important;
        margin-top: 50px;
        margin-bottom: 50px;
        justify-content: center !important;
    }
    .container-news {
        margin-top: 10px !important;
    }
    .square-us {
        display: none;
    }
    .square-new {
        position: relative !important;
        background: #D1EBFC !important;
    }
    .container-footer_news_us {
        padding-top: 0px !important;
    }
    .info-footer_news_us {
        grid-column: 2/7 !important;
        grid-row: 1/2;
    }
    .info-footer_news_us div ,.info-footer_news_us p{
        width: 100% !important;
    }
    .info-container {
        grid-column: 2/7 !important;
    }
    .info-container p {
        font-size: 18px !important;
    }
    .post-img-card-rigth {
        padding-top: 40px;
        padding-bottom: 0px;
        grid-column: 1/8 !important;
        display: flex !important;
        margin-top: 0px !important;
        margin-bottom: 40px !important;
        justify-content: center !important;
    }
    .card-body a, .card-body button {
        padding: 8px 15px !important;
        font-size: 9px !important;
    }
    .post-img-card-rigth {
        left: auto !important;
    }
    .img-card ,.square-img-card.right-up{
        max-width: 500px;
    }
    .etic .square-img-card.right-up {
        right: 30% !important;
        height: 340px !important;
        top: 70px!important;
        transform: rotate(-9.05deg) !important;
    }
    .img-card img {
        width: 100% !important;
    }
    .etic-pro {
        padding-top: 40px !important;
    }
    .container-products_business {
        width: 100% !important;
    }
    .container-news {
        background: #D1EBFC !important;
    }
    .tablet .container-news .square-slick {
        right: 20% !important;
        transform: rotate(-9.05deg) !important;
    }
    .post-sl-sbl {
        grid-column: 1/8 !important;
        padding-bottom: 80px;
    }
    .info-sl-sbl {
        grid-row: 1/2 !important;
        grid-column: 1/8 !important;
        width: 100% !important;
        margin-left: 45px;
        text-align: left !important;
    }
    .info-sbl {
        position: relative !important;
        right: 0 !important;
    }
    .stb-slick.tablet .slick-dots {
        left: 0 !important;
    }
    .square-stb {
        position: relative !important;
        background: #E6EEE4;
        background-image: none !important;
        margin-top: 70px;
    }
    .container-grid.sbl {
        padding-top: 40px !important;
    }
    .container-grid.sbl .info-pm {
        grid-column: 1/8 !important;
        justify-content: start;
        margin-left: 80px;
    }
    .container-grid.sbl .info-pm p {
        width: 90% !important;
        font-size: 12px !important;
    }
    .container-grid.sbl .play-known {
        padding-top: 100px;
    }
    .container-grid.sbl .play-button_pm {
        right: 20px !important;
    }
    .container-grid.pet .info-pet-sbl {
        grid-column: 1/8 !important;
        grid-row: 1/2 !important;
        text-align: left !important;
        max-width: 980px !important;
        display: block;
        margin-left: 170px;
    }
    .container-grid.pet {
        padding-top: 10px !important;
    }
    .post-pet-sbl {
        display: none;
    }
    .container-grid.tonel {
        margin-top: 0px !important;
    }
    .container-grid.tonel .info-container , .container-grid.first .info-container, .container-grid.community .info-container{
        grid-column: 1/7 !important;
        margin-left: 80px;
    }
    .container-grid.tonel .info-container p ,.container-grid.first .info-container p ,.container-grid.community .info-container p{
        font-size: 12px !important;
    }
    .container-grid.tonel .img-container ,.new-slick.work {
        grid-column: 1/8 !important;
    }
    .container-grid.first .square-img-card {
        max-width: 500px;
        height: 350px;
        top: 60px;
        right: 29% !important;
    }
    .new-slick.work {
        margin-left: 30px !important;
        margin-top: 60px;
    }
    .container-grid.videos .post-pet-sbl.activity , .container-grid.videos .new-slick.work.activity.tablet {
        display: block;
        width: 100% !important;
        grid-column: 1/8 !important;
        padding-top: 0px !important;
        padding-bottom: 180px !important;
    }
    .container-grid.videos .slick-initialized .slick-slide > div {
        width: 100% !important;
    }
    .container-grid.videos .new-slick.work.activity {
        right: 0px !important;
    }
    .container-grid.videos .new-slick.work.activity.tablet .slick-dots {
        left: 0px !important;
    }
    .container-grid.videos .new-slick.work.activity.tablet {
        padding-bottom: 10px !important;
    }
    .new-slick.work.activity .slick-slide > div > div {
        display: block !important;
    }
    .new-slick.work.activity .info-post-activity {
        left: 0 !important;
    }
    .container-grid.videos .square-pet-sbl.activity {
        left: 50px !important;
        top: 30px !important;
    }
    .container-grid.videos {
        background: #32711E;
    }
    .container-grid.videos .info-post-activity {
        grid-column: 1/8 !important;
    }
    .container-grid .info-pm {
        grid-column: 1/7 !important;
        margin-left: 100px;
    }
    .container-grid .play-known {
        grid-column: 1/8 !important;
        padding-top: 100px;
        justify-content: center !important;
    }
    .container-grid .play-known .square {
        right: 10px !important;
    }
    .container-grid .info-pm div{
        width: 100%;
    }
    .container-grid.why p {
        width: 100%;
    }
    .container-grid.why .square {
        left: 170px !important;
    }
    .container-grid.why .play-button_pm {
        right: 150px !important;
    }
    .container-grid.information {
        background: #fff;
    }
    .certifications-companies.info {
        grid-row: 1/2;
    }
    .certifications-companies.info p{
        width: 100% !important;
    }
    .container-grid.information .play-known .square {
        left: 160px !important;
    }
    .square-new.workwithme {
        display: none;
    }
    .container .container-grid.work {
        padding-top: 0px !important;
        background: #D1EBFC;
    }
    .container .container-grid.work .info-new_slick {
        grid-column: 1/8 !important;
        justify-content: flex-start !important;
        margin-left: 85px;
    }
    .container .new-slick.work.tablet .slick-dots {
        left: 0px !important;
        bottom: 30px !important;
    }
    .container-grid.elipse {
        background: #005286;
    }
    .container-grid.elipse .elipse-work {
        display: none;
    }
    .container-grid.elipse .info-pm_work {
        grid-row: 1/2;
        grid-column: 1/8 !important;
        text-align: center;
        margin-left: 0 !important;
    }
    .container-grid.elipse .info-pm_work h6 ,.container-grid.elipse .info-pm_work p , .accordion{
        max-width: 90% !important;
    }
    .container-grid.elipse .play-known .square {
        left: 160px !important;
    }
    .container-grid.elipse .info-container {
        grid-column: 1/7 !important;
        margin-left: 85px !important;
    }
    .container-grid.elipse .box-container {
        grid-column: 1/7 !important;
        width: 100% !important;
        margin-left: 85px !important;
    }
    .container-grid.elipse .box-container .container-accordion {
        max-width: 90% !important;
    }
    .container.workwithme {
        padding-bottom: 0px !important;
    }
    .container-grid.clients-page .info-clients {
        grid-column: 1/7;
        margin-left: 85px;
    }
    .container-grid.clients-page .info-clients div ,.container-grid.clients-page .info-clients p{
        width: 100% !important;
    }
    .container-grid.clients-page .prs-clients {
        display: none;
    }
    .container-grid.clients-page .info-client-footer {
        margin-left: 85px !important;
    }
}

@media only screen and (max-width: 768px) {
    .Rotate-slick.first::after {
        background-image: url("../images/img/bioformmovil.png") !important;
    }
    .Rotate-slick.movil.express::after {
        background-image: url("../images/img/expressmobile.png") !important;
    }
}