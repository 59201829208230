.Informations {
    position: relative;
    z-index: 1024;
    max-width: 1440px;
    margin: 0 auto;
    height: auto;
    background: #Fff;
    overflow: hidden;
}

.square-information {
    position: absolute;
    width: 1708.8px;
    height: 1519.8px;
    left: -150px;
    top: 210px;
    background: #005286;
    border-radius: 191px;
    transform: rotate(-16.18deg);
}

.Map_pm {
    padding-top: 150px;
}

.container-informations {
    position: relative;
    display: grid;
    background: #fff;
    z-index: 1;
    align-items: center;
    grid-template-columns: repeat(7,1fr);
    grid-gap: 60px;
}

.img-carbajal, .img-info_carbajal {
    position: relative;
    z-index: 1024;
    display: flex;
    grid-column: 4/8;   
}


.img-info_carbajal {
    justify-content: center;
    top: 190px;
}

.img-footer_news.informations {
    position: relative;
    margin-top: 300px;
}

.img-info_carbajal img {
    position: relative;
    right: 15px;
}

.img-info_carbajal h6 {
    margin-top: 40px;
    color: #fff;
    font-family: 'Amble-bold';
    font-size: 30px;
    margin-bottom: 20px;
}

.img-info_carbajal p {
    width: 512px;
    font-family: 'Amble-light';
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    line-height: 22px;
}

.square-carbajal {
    position: absolute;
    top: 0px;
    width: 720px;
    height: 471px;
    z-index: 1020;
    left: -20px;
    background: #D1EBFC;
    border-radius: 50px;
    transform: rotate(9.05deg);
}

.img-carbajal {
    width: 672px;
    height: 440px;
}

.img-carbajal .wc {
    width: 100%;
}

.img-carbajal img {
    position: relative;
    z-index: 1022;
    width: 100%;
    height: 100%;
    border-radius: 30px;
}

.img-carbajal::after {
    content: '';
    width: 100%;
    border-radius: 30px;
    height: 100%;
    background: rgba(0, 82, 134, 0.2);
    z-index: 9999999;
    position: absolute;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.info-carbajal {
    display: flex;
    grid-column: 2/4;
}

.info-carbajal.about p {
    font-size: 14px;
    color: #4D4D4C;
}

.info-carbajal div {
    width: 351px;
}

.info-carbajal img {
    position: relative;
}

.info-carbajal h6 {
    font-family: 'Amble';
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: #fff;
    margin-top: 30px;
}

.info-carbajal p {
    font-family: 'Amble-light';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 132.3%;
    color: rgba(255, 255, 255, 0.8);
    margin-top: 30px;
}

.container-documents {
    display: grid;
    background: #fff;
    max-width: 1440px;
    margin: 0 auto;
    padding-bottom: 80px;
    grid-template-columns: repeat(7, 1fr);
}

.info-documents {
    grid-column: 1/4;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.info-documents .idms {
    width: 383px;
}

.info-documents h5 {
    font-family: 'Amble';
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    color: #005286;
}

.info-documents p {
    font-family: 'Amble-light';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 132.3%;
    color: #4D4D4C;
    margin-top: 40px;
}

.documents {
    grid-column: 4/8;
    display: flex;
    justify-content: center;
}

.document {
    box-shadow: 0px 4px 20px rgba(216, 216, 216, 0.3);
    border-radius: 31.5px;
    margin-top: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 431px;
}

.document button{
    background: #009FE3;
    border: none;
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.document p {
    margin-left: 20px;
    font-family: 'Amble-light';
    text-decoration: underline;
    color: #005286;
    font-size: 14px;
}

.img-map_pm {
    grid-column: 2/6;
    display: flex;
    justify-content: center;
}

.img-map_pm img {
    max-width: 100%;
    object-fit: cover;
}

.info-map_pm {
    grid-column: 8/11;
    position: relative;
    right: 150px;
    padding-top: 20px;
}

.info-map_pm span {
    font-family: 'Amble-bold';
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 55px;
    color: #005286;
}

.info-map_pm h6 {
    font-family: 'Amble-bold';
    font-size: 50px;
    width: 500px;
    line-height: 35px;
    color: #005286;
}

.container-grid_img {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
}

.text-map_pm {
    text-align: center;
    color: #005286;
    font-family: 'Amble-light';
    font-size: 18px;
    margin-top: 20px;
}

.text-map_pm .bold {
    font-size: 31px;
    font-family: 'Amble-bold';
}

.footer-dc {
    position: relative;
    right: 40px;
}

.skills-gc {
    margin-top: 40px;
    display: flex;
    align-items: center;
}

.pm-inf_sk {
    text-align: center;
    margin-right: 60px;
}

.pm-inf_sk svg {
    margin-bottom: 10px;
}

.pm-inf_sk strong {
    color: #005286;
    font-family: 'Amble-bold';
    margin-top: 10px;
    font-size: 12px;
}

.pm-inf_sk p {
    color: #005286;
    font-family: 'Amble-light';
    font-size: 12px;
    width: 78px;
}

.footer-dc strong {
    font-family: 'Amble-bold';
}

.footer-dc {
    padding-top: 20px !important;
}

.more-btn button {
    font-family: 'Amble-light';
    font-size: 14px;
    color: #fff;
    padding: 8px 40px;
    background: transparent;
    border: 1px solid #fff;
    border-radius: 30px;
    margin-top: 20px;
    outline: none;
    cursor: pointer;
}

.img-map_pm.information {
    justify-content: flex-start;
}

@media only screen and (max-width: 1440px) {
    .img-values {
        max-width: 998px;
    }
    .certifications-companies.info {
        justify-content: flex-end !important;
    }
}