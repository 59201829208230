.Header-pm {
    width: 100%;
    background: #FFFFFF;
    height: 78px;
    z-index: 1024;
    box-shadow: 0px 4px 30px rgba(172, 172, 172, 0.15);
}

.Container-header_pm {
    position: relative;
    max-width: 1840px;
    margin: 0 auto;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(15, 1fr);
}

.phones {
    display: flex;
    position: relative;
    padding: 10px;
    color: #4D4D4C;
    grid-column: 5/15;
    font-family: 'Amble';
    font-size: 14px;
    justify-content: flex-end;
}

.phones .icon {
    margin-right: 10px;
}

.Container-header_pm .Logo-pm {
    display: flex;
    justify-content: center;
    grid-column: 1/3;
}

.Container-header_pm .Nav-pm {
    grid-column: 3/12;
    position: relative;
    display: flex;
    justify-content: flex-start;
}

.Container-header_pm .Nav-pm ul {
    display: flex;
    list-style: none;
}

.Container-header_pm .Nav-pm ul li {
    margin-left: 34px;
}

.Container-header_pm .Nav-pm ul li a {
    font-size: 14px;
    color: #4D4D4C;
    font-family: 'Amble-Light';
}

.Container-header_pm .Options-pm_qe {
    grid-column: 12/16;
    display: flex;
    justify-content: center;
    position: relative;
    top: 5px;
}

.Container-header_pm .Options-pm_qe .Quote-btn {
    margin-right: 20px;
}

.Container-header_pm .Options-pm_qe .Ecommerce-btn a,
.container-header-rs .Ecommerce-btn a {
    background: #009FE3;
    border-radius: 30px;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    cursor: pointer;
    font-family: 'Amble';
    font-size: 14px;
    font-weight: bold;
    padding: 10px 20px;
    text-decoration: none;
}

.container-header-rs .Ecommerce-btn a {
    display: none !important;
}

.Container-header_pm .Options-pm_qe .Ecommerce-btn a svg {
    margin-right: 10px;
}

.Nav-pm ul li {
    position: relative;
    z-index: 1;
}

.Nav-pm ul li a {
    text-decoration: none;
}

.Nav-pm ul li a.active {
    color: #8DB72E;
    font-family: 'Amble-bold';
    transition: 0.6s;
}

.Nav-pm ul li a.active::after {
    content: '';
    width: 100%;
    height: 3px;
    background: #8DB72E;
    position: absolute;
    border-radius: 2px;
    top: 42px;
    left: 0;
    transition: 0.6s;
}

.menus {
    display: flex;
    list-style: none;
}

.menu-items {
    position: relative;
}

.menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
}

.menu-items button {
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
}

.menu-items a,
.menu-items button {
    text-align: left;
}

.menu-sub-items a,
.menu-sub-items button {
    text-align: right !important;
    padding: 0.9rem 1rem;
}

.menu-sub-items {
    margin-left: 0px !important;
}

.menu-items a:hover,
.menu-items button:hover {
    background-color: #e3e3e3;
}

.arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
}

.dropdown {
    position: absolute !important;
    right: 0;
    left: auto;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
        0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 8rem;
    padding-top: 0.5rem;
    list-style: none;
    background-color: #fff;
    border-radius: 0.3rem;
    display: none !important;
}

.dropdown.show {
    display: block !important;
}

.dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
}

.select-sim {
    vertical-align: middle;
    position: relative;
    border: 1px solid #4D4D4C;
    box-sizing: border-box;
    border-radius: 30px;
    padding: 13px 65px 0px 15px;
    margin-left: 20px;
    height: 42px;
    font-family: 'Amble-bold';
    color: #4D4D4C;
    font-size: 14px;
}

.select-sim::after {
    content: "";
    background-image: url('../images/img/arrowselect.webp');
    font-size: 0.5em;
    font-family: arial;
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    right: 10px;
    transform: translate(90%, -30%);
}

.container-header-rs .close-modal {
    display: none;
}

.select-sim .options .option input {
    width: 0;
    height: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    position: absolute;
}

.select-sim .options .option input:checked+label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50px;
}

.select-sim .options .option input:checked+label span {
    margin-left: 10px;
}

.Quote-btn {
    position: relative;
}

.header-rs {
    display: none;
}

@media only screen and (max-width: 1440px) {
    .Container-header_pm .Nav-pm ul li {
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media only screen and (max-width: 1280px) {
    .movil-header {
        height: 70px;
    }

    .container-header,
    .container-header-height {
        display: none;
    }

    .header-rs {
        display: block;
        position: fixed;
        z-index: 9999999;
        width: 100%;
        background: #FFFFFF;
    }

    .container-header-rs {
        position: relative;
        max-width: 1840px;
        margin: 0 auto;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(15, 1fr);
        z-index: 999999;
    }

    .container-header-rs .Logo-pm {
        display: flex;
        padding: 10px;
        justify-content: center;
        grid-column: 1/3;
    }

    .options-pm-rs {
        grid-column: 13/16;
        display: flex;
        justify-self: flex-end;
        align-items: center;
        padding: 10px;
    }

    .bars {
        margin-left: 20px;
    }

    .container-header-rs .Nav-pm {
        position: absolute;
        width: 100%;
        z-index: 1;
        top: 45px;
        grid-column: 1/16;
        padding-left: 0;
        padding-right: 0;
    }

    .movile-active .container-header-rs .Nav-pm ul {
        list-style: none;
        width: 100%;
        background: #fff;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        -webkit-transform: translateX(0%) !important;
        transform: translateX(0%) !important;
        position: absolute;
        left: 0;
        top: -15px;
        display: table;
        padding-top: 10px;
        padding-bottom: 10px;
        -webkit-box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 1px 0px 0px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 2px 0px -1px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    }

    .container-header-rs .Nav-pm ul {
        list-style: none;
        width: 100%;
        background: #fff;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        -webkit-transform: translateX(-100%) !important;
        transform: translateX(-100%) !important;
        position: absolute;
        left: 0;
        display: table;
        padding-top: 10px;
        padding-bottom: 10px;
        -webkit-box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 1px 0px 0px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 2px 0px -1px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    }

    .container-header-rs .Nav-pm li {
        width: 100%;
        border-top: 1px solid rgba(118, 118, 118, 0.2);
        padding: 15px 20px;
    }

    .container-header-rs .Nav-pm ul li a {
        font-family: 'Amble-bold';
        color: #4D4D4C;
        font-size: 14px;
    }

    .container-header-rs .Nav-pm ul li a.active-mv {
        color: #009FE3 !important;
        border-bottom: none !important;
        font-size: 14px;
        font-family: 'Amble-bold';
    }

    .container-header-rs .Nav-pm ul li a.active-menu-item-mv {
        color: #009FE3 !important;
        border-bottom: none !important;
        font-size: 14px;
        font-family: 'Amble-bold';
        padding-left: 15px;
    }

    .container-header-rs .Nav-pm ul li a.menu-item-mv {
        color: #4D4D4C !important;
        border-bottom: none !important;
        font-size: 14px;
        font-family: 'Amble-bold';
        padding-left: 15px;
    }

    .movile-active .container-header-rs .Ecommerce-btn button {
        display: block !important;
        margin-top: 6px;
        margin-bottom: 2px;
    }

    .movile-active .container-header-rs .Logo-pm svg {
        display: none;
    }

    .movile-active .container-header-rs .close-modal {
        display: block;
    }

    .movile-active .container-header-rs .bars,
    .movile-active .container-header-rs .Quote-btn {
        display: none;
    }
}

.Lng-es {
    z-index: 1;
    background: #fff;
    border-bottom-left-radius: 26px;
    border-bottom-right-radius: 26px;
}

.Lng-es button {
    margin-left: 10px;
    text-decoration: none;
    font-family: 'Amble-bold';
    color: #005286;
    font-size: 14px;
    margin-top: 5px;
    padding: 10px 0px;
    background: transparent;
    border: 1px solid #ffffff;
    border-radius: 26px;
    cursor: pointer;
    outline: none;
}

.Lng-en {
    z-index: 1;
    background: #fff;
    border-bottom-left-radius: 26px;
    border-bottom-right-radius: 26px;
}

.Lng-en button {
    margin-right: 10px;
    text-decoration: none;
    font-family: 'Amble-bold';
    color: #005286;
    font-size: 14px;
    margin-top: 5px;
    padding: 10px 0px;
    background: transparent;
    border: 1px solid #ffffff;
    border-radius: 26px;
    cursor: pointer;
    outline: none;
}

.Lng {
    z-index: 1;
    background: #fff;
    border-bottom-left-radius: 26px;
    border-bottom-right-radius: 26px;
}

.Lng p {
    text-decoration: none;
    font-family: 'Amble-bold';
    color: #005286;
    font-size: 14px;
    margin-top: 5px;
    padding: 10px 10px;
    background: transparent;
    border: 1px solid #ffffff;
    border-radius: 26px;
    outline: none;
}