.Menu-Category_pm, .Distributor-search {
    padding-top: 10px;
    background: #005286;
    padding-bottom: 10px;
}

.Distributor-search {
    padding-bottom: 20px;
}

.Search-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 90%;
}

.Search {
    display: grid;
    grid-template-columns: repeat(10,1fr);
    align-items: center;
    grid-gap: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.Search button.btn-back {
    grid-column: 2/3;
}

.Search .Search-container {
    grid-column: 3/10;
}

.Search button.btn-back {
    background: #009FE3;
    border-radius: 30px;
    border: none;
    justify-content: space-between;
    align-items: center;
    outline: none;
    color: #fff;
    cursor: pointer;
    font-family: 'Amble';
    font-size: 14px;
    font-weight: bold;
    padding: 14px 20px;
}

.Search-container button {
    top: 5px;
    right: 5px;
    position: absolute;
    background: transparent;
    border: none;
}

.Search-container svg.search {
    position: absolute;
    left: 20px;
}

.Search-container svg.filter {
    position: absolute;
    right: 10px;
}


.Quotes-pm .Products-Quote.Search-Product .products-default .pagination {
    display: none !important;
}

.Search-container input {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px #02426A;
    border-radius: 40px;
    border: none;
    padding: 15px 5px 15px 20px;
    font-family: 'Amble-light';
    font-size: 14px;
    outline: none;
}

.Search-container.lines {
    margin-bottom: 0;
}

.Search-container.lines input {
    padding: 15px 5px 15px 50px;
    margin-bottom: 30px;
}

.Search-container.lines svg.search {
    position: absolute;
    left: 20px;
    top: 15px;
}

.Search-container input::placeholder {
    color: #C4C4C4;
}

.categories-products {
    display: flex;
}

.menu-categories {
    display: flex;
    width: 100%;
}

.menu-categories ul {
    display: flex;
    justify-content: space-around;
    width: 100%;
    list-style: none;
}

.menu-categories li {
    margin-bottom: 20px;
}

.menu-categories li a {
    color: #f8f8f8;
    text-decoration: none;
    font-family: 'Amble-light';
    font-size: 14px;
}

.Products-Quote_pm {
    position: relative;
    margin: 0 auto;
    background: #fff;
}

.Container-Products_Quote_pm {
    position: relative;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(2, 430px);
    grid-gap: 20px;
    padding-top: 20px;
    background: #fff;
}

.Products-Quote_1 {
    overflow: hidden;
    position: relative;
    grid-column: 1/6;
    width: 100%;
    border-radius: 0px 50px 50px 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.img-bioform_product {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.img-bioform_product img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.info-product_bf {
    left: 30px;
    margin-top: 50px;
    position: absolute;
    z-index: 3;
}

.info-product_bf h4{
    color: #4A7729;
    font-size: 40px;
    width: 400px;
    margin-bottom: 50px;
    font-family: 'Amble-Bold';
    font-weight: bold;
}

.info-product_bf a{
    text-decoration: none;
    color: #4D4D4C;
    font-size: 14px;
    font-family: 'Amble';
    padding: 10px 40px;
    cursor: pointer;
    outline: none;
    border-radius: 50px;
    background: #fff;
    border: 1px solid #4D4D4C; 
}

.Products-Quote_2 {
    position: relative;
    grid-column: 6/9;
    width: 100%;
    height: 100%;
    background: transparent;
    overflow: hidden;
    border-radius: 50px 0px 0px 50px;
}

.info-product_bf_2 {
    margin-top: 50px;
    left: 40px;
    position: absolute;
    z-index: 3;
}

.info-product_bf_2 h4{
    color: #4D4D4C;
    width: 317px;
    margin-bottom: 50px;
    font-size: 40px;
    font-family: 'Amble-Bold';
    font-weight: bold;
}

.info-product_bf_2 a{
    color: #4D4D4C;
    font-size: 14px;
    font-family: 'Amble';
    text-decoration: none;
    padding: 10px 40px;
    cursor: pointer;
    outline: none;
    border-radius: 50px;
    background: #fff;
    border: 1px solid #4D4D4C; 
}

.Products-Quote_3 {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    grid-column: 1/4;
    background: #F9F8F8;
    border-radius: 0px 50px 50px 0px;
}

.info-product_bf_3 {
    width: 317px;
    margin-top: 50px;
    left: 40px;
    position: absolute;
    z-index: 3;
}

.info-product_bf_3 h4{
    color: #4c4c4c;
    font-size: 40px;
    font-family: 'Amble-Bold';
    font-weight: bold;
    margin-bottom: 50px;
}

.info-product_bf_3 a{
    color: #4D4D4C;
    font-size: 14px;
    font-family: 'Amble';
    padding: 10px 40px;
    cursor: pointer;
    outline: none;
    border-radius: 50px;
    background: #fff;
    text-decoration: none;
    border: 1px solid #4D4D4C; 
}

.Products-Quote_4 {
    overflow: hidden;
    position: relative;
    grid-column: 4/9;
    border-radius: 50px 0px 0px 50px;
}


.info-product_bf_4 {
    width: 382px;
    margin-top: 50px;
    left: 40px;
    position: absolute;
    z-index: 3;
}

.info-product_bf_4 h4{
    color: #4c4c4c;
    font-size: 40px;
    font-family: 'Amble-Bold';
    font-weight: bold;
    margin-bottom: 50px;
}

.info-product_bf_4 a{
    color: #4D4D4C;
    font-size: 14px;
    font-family: 'Amble';
    padding: 10px 40px;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    border-radius: 50px;
    background: #fff;
    border: 1px solid #4D4D4C; 
}

.tree-left {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    bottom: -84px;
    left: 0px;
    width: 232.98px;
    height: 284.53px;
    z-index: 3;
    background-image: url('../images/img/fondocanaright.webp');
}

.tree-right {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    right: 0px;
    border-radius: 0px 50px 50px 0px;
    width: 232.98px;
    height: 284.53px;
    z-index: 3;
    background-image: url('../images/img/fondocanaleft.webp');
}


.bioform {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    bottom: -40px;
    right: 50px;
    width: 132.98px;
    height: 184.53px;
    z-index: 3;
    background-image: url('../images/img/bioform.webp');
}

.pen {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    bottom: -30px;
    right: 20px;
    width: 186px;
    height: 226px;
    left: -50px;
    z-index: 1;
    background-image: url('../images/img/pen1.webp');
    opacity: 1;
    transform: rotate(121.08deg);
}

.pen_2 {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    bottom: 20px;
    left: 20px;
    width: 186px;
    height: 226px;
    z-index: 1;
    background-image: url('../images/img/pen2.webp');
    opacity: 0.5;
    transform: rotate(180deg);
}

.filter-shadow {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    background: linear-gradient(180deg, #FFEEE4 -55.97%, rgba(242, 226, 217, 0) 100%);
    border-radius: 0px 50px 50px 0px;
}

.filter-shadow-white {
    position: absolute;
    z-index: 2;
    width: 120%;
    height: 100%;
    top: -40px;
    left: -10px;
    background: linear-gradient(180deg, #EBF7FF 0%, rgba(235, 247, 255, 0) 106.01%);
    border-radius: 50px 0px 0px 50px;
}

.filter-shadow.blue {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 200px;
    bottom: 0;
    background: rgba(190, 230, 255, 0.5);
    background-blend-mode: multiply;
    filter: blur(79px);
    border-radius: 0px 0px 50px 0px;
}

.img-bioform_product_4 {
    display: flex;
    justify-content: flex-end;
}
.img-bioform_product_4 img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
}

.Products-populate_pm {
    margin: 0 auto;
    background: #fff;
    padding-top: 80px;
}

.Container-products_populate .slick-slide > div > div {
    display: flex !important;
    padding-bottom: 80px !important;
    justify-content: center !important;
}

.Products-populate_pm  h4 {
    color: #005286;
    text-align: center;
    font-family: 'Amble-bold';
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
}

.Products-populate_pm  p {
    font-family: 'Amble-light';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #4c4c4c;
    text-align: center;
    margin-top: 20px;
}

.products-populate {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
}

.square-products {
    position: absolute;
    width: 280px;
    top: 30px;
    height: 280px;
    background: #D1EBFC;
    z-index: 1;
    border-radius: 58px;
    transform: matrix(0.91, -0.42, -0.42, -0.91, 0, 0);
}

.products-populate {
    margin-top: 40px;
    position: relative;
}

.products-populate h5 {
    position: absolute;
    top: 50px;
    color: #000;
    width: 252px;
    font-size: 22px;
    font-family: 'Amble-bold';
    left: 60px;
    z-index: 2;
}

.products-populate button{
    position: absolute;
    z-index: 3;
    font-family: 'Amble';
    background: #005286;
    cursor: pointer;
    right: 50px;
    border: 1px solid #005286;
    box-sizing: border-box;
    border-radius: 30px;
    color: #fff;
    font-size: 15px;
    padding: 10px 20px;
}

.products-populate img {
    position: relative;
    left: -40px;
    z-index: 3;
}

.Container-products_populate {
    width: 93%;
    display: flex;
    margin: 0 auto;
}

.populate-slick .slick-next {
    right: 20px !important;
    z-index: 10000 !important;
    top: 280px;
}

.populate-slick .slick-prev {
    left: -20px !important;
    z-index: 10000 !important;
    top: 280px;
}

.populate-slick .slick-next::before, .populate-slick .slick-prev::before {
    color: #fff !important;
    padding: 0px 20px;
    background: rgba(0, 82, 134, 0.6);
    border-radius: 50%;
    font-size: 40px;
    opacity: 1 !important;
    line-height: 100px;
    font-family: 'Poppins';
}

.populate-slick .slick-prev::before {
    content: '<';
}

.populate-slick .slick-next::before {
    content: '>';
}

.makeStyles-paper-1 {
    outline: none;
}

.categories-business {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.categories-business.used {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap !important;
}

.categories-business.used div {
    margin-top: 15px;
}

.business-choose {
    position: relative;
    display: flex;
    justify-content: space-between;
}

.business-choose a {
    text-decoration: none;
    font-family: 'Amble-light';
    font-size: 13px;
    color: #4c4c4c;
    border: 1px solid #005286;
    border-radius: 20px;
    margin-bottom: 20px;
    background: #fff;
    padding: 10px 20px 10px 20px;
    margin-right: 15px;
    display: flex;
    align-items: center;
}

.business-choose .active-category {
    background: #D1EBFC;
}

.business-choose a img {
    width: 16px;
    margin-right: 10px;
}

.business-choose .detail-product {
    width: 15px;
    top: 12px;
    position: absolute;
    left: 20px;
}

.categories-business svg{
    position: absolute;
    left: 20px;
    top: 10px;
}

.categories-business button{
    border: 1px solid #005286;
    box-sizing: border-box;
    border-radius: 20px;
    background: #fff;
    font-family: 'Amble';
    padding: 10px 40px 10px 50px;
    margin-right: 20px;
    outline: none;
}


.categories-business button:focus{
    outline: none;
    background: #D1EBFC;
    border: 1px solid #005286;
    box-sizing: border-box;
}

h2.title-choose_business {
    font-family: 'Amble';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 20px;
    color: #4D4D4C;
}  

.img-bioform_product_2 {
    position: relative;
    height: 100%;
    background: transparent;
}

.img-bioform_product_2 img {
    position: absolute;
    object-fit: cover;
    z-index: 1;
    height: 100%;
    width: 100%;
}

.img-bioform_product_3 img {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    bottom: 0;
}

.Products-Quote {
    grid-column: 3/12;
    background: #fff;
    height: auto;
    padding: 20px;
    margin-bottom: 0px;
}

.Filter_pm {
    margin-top: 20px;
}

.container-filter_pm {
    display: grid;
    background: #009FE3;
    box-shadow: 0px 4px 20px rgba(216, 216, 216, 0.3);
    border-radius: 14px;
    grid-template-columns: repeat(11,1fr);
    color: #fff;
    align-items: center;
    padding: 0px 0px 0px 20px;
    font-size: 14px;
    font-family: 'Amble-light';
}

.Name-category {
    grid-column: 1/4;
    padding: 10px;
}

.Name-category p {
    font-family: 'Amble';
    font-size: 14px;
}

.img-detail_product .img-product button.slick-next , .img-detail_product .img-product button.slick-prev {
    display: none !important;
}

.Count-products {
    grid-column: 4/7;
    position: relative; 
    padding: 20px;
    height: 100%;
}

.Count-products::after {
    content: '';
    position: absolute;
    border-right: 1px solid #fff;
    height: 100%;
    right: 0;
    top: 0;
}

.Order-by {
    display: flex;
    grid-column: 7/10;
    justify-content: flex-start;
    margin-left: 20px;
}

.select-order {
    margin-left: 20px;
    font-family: 'Amble';
}

  /* .img-product .slick-prev:before, .slick-next:before {
  display: none; 
}*/

.img-product .slick-dots {
    transform: rotate(90deg);
    bottom: 100px;
    left: -200px !important;
}

.img-product .slick-dots li.slick-active button:before {
    background: #005286 !important;
}

.img-product  .slick-dots li button:before, .img-product  .slick-dots li.slick-active button:before {
    border: 2px solid #005286;
    border-radius: 50%;
}

.img-product .slick-dots li button:before {
    width: 5px !important;
    height: 5px !important;
}

.View-options {
    display: flex;
    justify-content: flex-end;
    grid-column: 10/11;
    font-family: 'Amble';
}

.products-default {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: repeat(3,1fr);
}

.products-default.populate {
    grid-template-columns: none !important;
}

.products-default .square-products {
    position: absolute;
    width: 234px;
    top: 30px;
    height: 234px;
    background: #D1EBFC;
    z-index: 1;
    border-radius: 58px;
    transform: matrix(0.91, -0.42, -0.42, -0.91, 0, 0);
}

.products-default{
    margin-top: 40px;
    position: relative;
}

.products-default h5 {
    position: absolute;
    top: 25px;
    color: #000;
    width: 223px;
    font-size: 15px;
    font-family: 'Amble-bold';
    left: 0px;
    z-index: 1000;
}

.products-default .product {
    position: relative;
    width: 260px;
    height: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.products-default .product .img-product {
    position: absolute;
    top: 50px;
    left: 0;
    width: 223px;
}

.img-product img {
    max-width: 100%;
}

.product > a {
    position: absolute;
    z-index: 3;
    right: -70px;
    color: #fff;
    top: 140px;
    font-family: 'Amble';
    text-decoration: none;
    background: #005286;
    cursor: pointer;
    border: 1px solid #005286;
    box-sizing: border-box;
    border-radius: 30px;
    font-size: 15px;
    outline: none;
    padding-top: 10px;
    padding-bottom: 10px;
}

.product > a > a {
    color: #fff;
    text-decoration: none;
    width: 100%;
    padding: 10px 25px;
}

.products-default img {
    position: relative;
    z-index: 3;
}

.products-default .square-products.Ecoamigable {
    background: #FCE9DE;
}

@media only screen and (max-width: 1440px) {
    .Container-header_pm .Nav-pm {
        grid-column: 3/13;
    }
    .Container-header_pm .Options-pm_qe {
        grid-column: 13/16;
    }
}

@media only screen and (max-width: 1280px) {
    .square-products ,.products-default .square-products{
        max-width: 200px;
        height: 200px;
    }
    .products-populate img {
        max-width: 200px;
    }
    .products-populate {
        margin-bottom: 80px;
    }
    .populate-slick .slick-next ,.populate-slick .slick-prev {
        top: 150px;
    }
    .product > a {
        right: -20px;
    }
    .square-sl-sbl {
        width: 487px;
        height: 343px;
        left: 190px !important;
    }
    .info-sl-sbl p {
        margin-top: 10px !important;
    }
    .square-img-card {
        right: -120px !important;
    }
    .square-pet-sbl {
        left: 60px;
    }
    .new-slick.work.sbl.community.table .slick-dots {
        left: -370px !important;
    }
    .square-pet-sbl.activity {
        left: 170px !important;
    }
    .new-slick.work.activity.tablet .slick-dots {
        left: 630px !important;
    }
}